const PlusIcon = props => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line x1="5" y1="13" x2="20" y2="13" stroke="white" />
    <line x1="12.5" y1="5.5" x2="12.5" y2="20.5" stroke="white" />
  </svg>
);
export default PlusIcon;
