import TooltipFormat from '@/components/TooltipFormat';
import helper from '@/assets/images/imgQuestion.png';
import googleClendarIcon from '@/assets/images/google-calendar-icon.png';
import outlookIcon from '@/assets/images/outlook-icon.png';
import useWindowDimensions from '@/commons/useWindowDimensions';
import {
  ExclamationCircleOutlined,
  CloseOutlined,
  GoogleOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import config from '@/config';
import iconGoogle from '@/assets/images/google.png';
import iconOffice from '@/assets/images/microsoft.png';
import MicrosoftLogin from 'react-microsoft-login';
import GoogleLogin from 'react-google-login';
import { Button, Form, Input, Modal, Spin, message, Tooltip } from 'antd';
import { connect } from 'dva';
import React, { useEffect, useState } from 'react';
import { useHistory, useIntl } from 'umi';
import { profileFromStorage } from '@/commons/function';
import styles from './styles.less';
import HeaderMobile from '@/components/Mobile/Header';
import iconBack from '@/assets/images/i-back-white.png';
import { ROUTER } from '@/constant';
const listDataTooltipCancelPackage = [
  '契約中の有料プランの解約ができます。',
  '解約しても契約期間内は有料プランの機能を利用することが可能です。',
  '契約期間が終了するとプランが終了し、有料プランの機能が利用できなくなります。',
];
const listDataTooltipUnlinkPackage = [
  'Google・Microsoftのアカウントとの連携を解除できます。',
  '解除すると、カレンダーへの自動追加やダブルブッキングの防止ができなくなるのでご注意ください。',
];

const listDataTooltipDeleteAccount = [
  'アカウントのデータをすべて削除することができます。',
  '削除後に、セキュリティのためデータを削除しますので、',
  'アカウントの復旧はできないのでご注意ください。',
];

const CollaborationPage = props => {
  const { dispatch, calendarStore, eventStore } = props;
  const { width } = useWindowDimensions();
  const intl = useIntl();
  const { formatMessage } = intl;
  const [loadingDeleteAccount, setLoadingDeleteAccount] = useState(false);
  const [loadingCancelPlan, setLoadingCancelPlan] = useState(false);
  const [disableCancelPlan, setDisableCancelPlan] = useState(true);
  const [detailProfile, setDetailProfile] = useState({});
  const profile = profileFromStorage();
  const [loginPage, setLoginPage] = useState(true);
  const [acceptTerm, setAcceptTerm] = useState(loginPage);
  const [urlMeet, setUrlMeet] = useState('');
  const [urlZoom, setUrlZoom] = useState('');
  const redirectUri = `${window.location.protocol}//${window.location.host}/msteam-login-success`;
  const urlMSTeam = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${config.MICROSOFT_CLIENT_KEY}&scope=User.Read Calendars.Read Calendars.ReadWrite offline_access&response_type=code&redirect_uri=${redirectUri}&state=ClientStateGoesHere&prompt=login`;

  useEffect(() => {
    const payload = {
      setUrlMeet: setUrlMeet,
    };
    dispatch({ type: 'CALENDAR_CREATION/loadingData', payload });
  }, []);

  const responseGoogle = googleResponse => {
    if (!googleResponse.error) {
      const payload = {
        token: googleResponse.code,
        account_type: localStorage.getItem('type') | 0,
      };
      dispatch({ type: 'MASTER/googleLogin', payload });
    }
  };

  const loginGoogleFailed = googleResponse => {};

  const microsoftLogin = async accountType => {
    const redirectUri =
      window.location.protocol +
      '//' +
      window.location.host +
      '/get-code-microsoft';
    const clientId = config.MICROSOFT_CLIENT_KEY;
    const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&scope=User.Read Calendars.Read Calendars.ReadWrite offline_access&response_type=code&redirect_uri=${redirectUri}&state=ClientStateGoesHere&prompt=login`;
    const external = window.open(
      url,
      '',
      'width=480,height=800,top=400,left=400',
    );
    localStorage.removeItem('code');
    const interval = setInterval(() => {
      const code = localStorage.getItem('code');
      if (code) {
        external?.close();
        const payload = {
          token: code,
          account_type: accountType,
        };
        dispatch({ type: 'MASTER/microsoftLogin', payload });
        localStorage.removeItem('code');
      }

      if (external?.closed || code) {
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleClickCancelPlan = account_type => {
    Modal.confirm({
      title: formatMessage({ id: 'i18n_cancel_collaboration_confirm' }),
      icon: <ExclamationCircleOutlined />,
      okText: formatMessage({ id: 'i18n_confirm_delete' }),
      cancelText: formatMessage({ id: 'i18n_confirm_not_delete' }),
      className: styles.logoutConfirm,
      onOk: () => {
        dispatch({
          type: 'MASTER/cancelAccountIntegrates',
          payload: {
            showMessage: message,
            formatMessage,
            setDetailProfile,
            detailProfile,
            account_type,
          },
        });
      },
    });
  };

  const handleClickDeleteAccount = () => {
    Modal.confirm({
      title: formatMessage({ id: 'i18n_delete_account_confirm' }),
      icon: <ExclamationCircleOutlined />,
      okText: formatMessage({ id: 'i18n_confirm_delete' }),
      cancelText: formatMessage({ id: 'i18n_confirm_not_delete' }),
      className: styles.logoutConfirm,
      onOk: () => {
        dispatch({
          type: 'MASTER/deleteProfile',
          payload: {
            loadingFunc: setLoadingDeleteAccount,
            showMessage: message,
            formatMessage,
          },
        });
      },
    });
  };

  const handleClickUnlinkage = () => {
    Modal.confirm({
      title: formatMessage({ id: 'i18n_unlinkage_confirm' }),
      icon: <ExclamationCircleOutlined />,
      okText: formatMessage({ id: 'i18n_release' }),
      cancelText: formatMessage({ id: 'i18n_will_not_release' }),
      className: styles.logoutConfirm,
      onOk: () => {
        dispatch({
          type: 'MASTER/accountUnlinkage',
          payload: {
            showMessage: message,
            formatMessage,
            setDetailProfile,
            detailProfile,
          },
        });
      },
    });
  };

  return (
    <div className={styles.collaborationContainer}>
      <HeaderMobile
        title={formatMessage({ id: 'i18n_link_company_other' })}
        isShowLeft={true}
        itemLeft={{
          event: 'history',
          icon: iconBack,
          bgColor: 'bgPrimaryBlue',
        }}
      />
      <div style={{ padding: '30px 10px' }}>
        <div className={styles.groupPart}>
          <div style={{ marginBottom: 20 }}>
            <img src={googleClendarIcon} alt="" />
            {formatMessage({ id: 'i18n_collaboration_google_title' })}
          </div>
          <div style={{ textAlign: 'right' }}>
            {profile?.is_link_google ? (
              <Button
                type="primary"
                danger
                disabled={profile?.google_email}
                onClick={() => handleClickCancelPlan(1)}
              >
                {formatMessage({ id: 'i18n_btn_cancel_collaboration' })}
              </Button>
            ) : (
              <a
                target="_blank"
                href={calendarStore.urlMeet}
                className={`${styles.btnSocial}`}
              >
                <img src={iconGoogle} alt={'Google'} />
                {formatMessage({ id: 'i18n_calendar_link_other_google' })}
              </a>
            )}
          </div>
        </div>
        <div className={styles.groupPart}>
          <div>
            <img src={outlookIcon} alt="" />
            {formatMessage({ id: 'i18n_collaboration_microsoft_title' })}
          </div>
          <div style={{ textAlign: 'right' }}>
            {profile?.is_link_microsoft ? (
              <Button
                type="primary"
                danger
                disabled={profile?.microsoft_email}
                onClick={() => handleClickCancelPlan(2)}
              >
                {formatMessage({ id: 'i18n_btn_delete_linked' })}
              </Button>
            ) : (
              <a
                target="_blank"
                href={urlMSTeam}
                className={`${styles.btnSocial}`}
              >
                <img src={iconOffice} alt={'Microsoft'} />
                {formatMessage({ id: 'i18n_calendar_link_other_microsoft' })}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(({ PAYMENT, CALENDAR_CREATION, EVENT }) => ({
  paymentStore: PAYMENT,
  calendarStore: CALENDAR_CREATION,
  eventStore: EVENT,
}))(CollaborationPage);
