import React from 'react';

function CheckedWithBox() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="23"
      height="19"
      viewBox="0 0 23 19"
    >
      <image
        id="チェックボックスアイコン_1_"
        dataName="チェックボックスアイコン (1)"
        width="23"
        height="19"
        opacity="0.902"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAATCAYAAAB7u5a2AAAABHNCSVQICAgIfAhkiAAAAatJREFUOE+tlMsrRVEUh12S5DFFmDA1IwNmklchA3fK0MTMiKkkE/IaSTfJxCORP0AxlEde4ZaBTIxk5NX1/XROLdu55z7Y9XX23mut31r7cXYkkUjk/GOLoDUNM3AfQbyNTj5kkiUX/0rYgwdT3A79Bm8uKvHHLCs/Jm4Qnrz4bb6NRisu8TgThRkmUIVDJsYV/sQ2IPEbOsWwCDEToIpeoSogsd2KDexNxkfCnXBuxUcdce1rLdyGrGodW7Oxv9HvgTPNWfEJxvOeow74AKqhHw4DEgRV3I7fpe8bJF6KcR/KjGDUS+ZPucLvGHrhxBYRJF6CwybUOdW2ML6GoIo7mL9wV5dsW+S3Aq0m4Jn+HdSbuQ+vYl3LXy1MXM4x0E8W1PTTdcFpEnvSA7X+ywy0bNu0x7oVSYXlnKpyX9BNoGTf181rOqcXdwXpiituF7Tf3XBkhMbol8PwX8QLCK6BKyPSR38BdLtCxUdwWHOzpxhrBRUwC5Nhla9i3AK9yek03ZY50NOrd2k8SDzbJ9dqxRjobfrRdKD6AYogzyOdqn0fXUmtYAmm3MAv8cabAFXPXg8AAAAASUVORK5CYII="
      />
    </svg>
  );
}

export default CheckedWithBox;
