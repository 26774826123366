export default {
  i18n_base_smoooth: 'Base Smoooth',
  i18n_header_menu_1: 'ホーム',
  i18n_header_menu_2: 'サポート',
  i18n_header_menu_3: 'アカウント',
  i18n_header_menu_4: 'カレンダー選択',
  i18n_header_menu_5: 'テンプレート',
  i18n_new_apply: '＋新規追加',
  i18n_all_user: 'すべてのユーザー',
  i18n_team: 'チーム',
  i18n_all_team: 'すべてのチーム',
  i18n_all_team_user: '全てのユーザー＆チーム',
  i18n_create_team: 'チーム作成',
  i18n_in_comming_event: '今後の予定',
  template_1: 'テンプレート1をもとに作成',
  template_2: 'テンプレート2をもとに作成',
  template_3: 'テンプレート3をもとに作成',
  i18n_error_create_team_Title: 'お支払いページに移動しますか？',
  i18n_error_create_team_Description:
    'アカウント数が足りないので、御利用いただくためにアカウント購入をお願いします。',
  i18n_error_create_team_Description_Member:
    'アカウント数が足りないので、ご利用いただくために管理者に連絡してくださいます。',
  i18n_passed_event: '過去の予定',
  i18n_created_by_app: '日程調整ページ',
  i18n_exactly_created: '1人にURL送信',
  i18n_exactly_created_tooltip:
    '特定の個人とミーティング設定は「ピンポイント設定」をご利用ください。使用例）会食、ランチミーティング、面談、1on1ミーティングなど',
  i18n_list_event: '予約一覧',
  i18n_tab_list_event_tooltip:
    'タイムマッチを利用いただいて日程調整が完了した予定に関して、\n' +
    '「今後の予定」と「過去の予定」で確認いただけます。\n' +
    'それぞれの予定詳細や、日程調整相手の情報も確認できます。\n' +
    '\n' +
    'また、オンラインミーティングのURLも自動発行され、\n' +
    '各予定に表示されている当該ボタンをクリックいただくだけで\n' +
    'ミーティングにスムーズに参加いただけます。\n' +
    '\n' +
    'さらに、予定をキャンセルすることも可能です。\n',
  i18n_tab_vote: '投票',
  i18n_tab_preview_text_in: '人中',
  i18n_tab_preview_text_after: '人が投票済み',
  i18n_error_vote_add_block:
    '投票時における候補日程は最大30個まで選択できます。',
  i18n_vote_title: '投票者',
  i18n_copy_menu: 'この設定をコピー',
  i18n_tab_vote_tooltip: `
  3社間以上の日程調整を行う際に投票形式で日程調整できます。
  オンラインミーティング用URLを自動発行することが可能です。
  例：A社・B社・C社の3社で日程調整する（ミーティング・ランチミーティング・会食する） また、チーム単位での日程調整することも可能です。
  例：A社の社員Bさん・Cさん・DさんをチームEとする。
  チームEがクライアントである法人F社・法人G社の3社間で日程調整する。
  ※Bさん・Cさん・Dさん・法人F社・法人G社での日程調整が可能です。`,
  i18n_tab_url_copy: 'URLコピー',
  i18n_tab_send_menu: 'メール送付',
  i18n_tab_votingConfirm: '投票確定',
  i18n_error_not_add_member: '追加する権限がありません',
  i18n_company_name: '会社名',
  i18n_contact_info: '連絡先',
  i18n_comment: 'コメント',
  i18n_view_detail: '詳細',
  i18n_step_1_invite_member: 'メンバー招待',
  i18n_step_2_choose_day: '日程選択',
  i18n_step_1_confirm_day: '日程確定',
  i18n_frame_note: '※ 2 パターンございます。',
  i18n_step_1_invite_email: 'メールで招待する',
  i18n_pattern: 'パターン',
  i18n_copy_link: 'リンクをコピー',
  i18n_confirm_invite_member: '最終確定',
  i18n_sort_vote: '投票数が多い順に並び替え',
  i18n_invite_member_note_2:
    '※下記の投票結果を確認し、「日程選択」をクリックしてください。',
  i18n_invite_member_note_3:
    '※上記で日程を確認後、「最終確定」をクリックしてください。',
  i18n_cancel: 'キャンセル',
  i18n_clear: 'リセット',
  i18n_zoom_url: 'Zoom URLはこちら',
  i18n_choose_title: '日程選択',
  i18n_meeting_location: 'ミーティング場所',
  i18n_month: '月',
  i18n_smoooth: 'SMOOOTH',
  i18n_footer_service: '利用規約',
  i18n_footer_privacy: 'プライバシーポリシー',
  i18n_footer_law: '特定商取引法に基づく表記',
  i18n_url_step_1: 'カレンダーのURLを設定',
  i18n_url_step_2: 'スケジュール簡単設定',
  i18n_url_content_1: '「あなた」のスケジュールURLを下記にて設定ください。',
  i18n_url_content_2: '「日程調整するお相手」に送付するURLになります。',
  i18n_url_content_3: ' ※半角英数字のみ入力可能です。',
  i18n_url_rule_1: ' アカウント登録が完了しました。',
  i18n_url_rule_2: ' 下記より簡単な初期設定をお願いします。（所要時間：30秒）',
  i18n_btn_later: ' 後で設定する',
  i18n_btn_next: '次へ',
  i18n_btn_decide: '決定する',
  i18n_back: '戻る',
  i18n_create: '作成',
  i18n_complete: '完了する',
  i18n_advanced_setting: '詳細設定',
  i18n_calendar_creation_step_1: '会議の基本設定を行います。',
  i18n_calendar_creation_step_2: '日程調整したい日時を下記より選択ください。',
  i18n_calendar_creation_step_2_tips:
    '※複数選択・移動・削除等も簡単に操作できます。',
  i18n_adjust_pagename: '日程調整ページ名',
  i18n_required_name_notice: '日程調整ページ名を入力ください。',
  i18n_required_time_default: '1つ以上、曜日を選択ください。',
  i18n_meeting_format: 'ミーティング形式',
  i18n_required_time: 'ミーティング時間',
  i18n_meeting_place: 'ミーティング場所',
  i18n_travel_time: '移動時間',
  i18n_check_null_block: '候補日程ブロックは最低1つ作成する必要があります。',
  i18n_required_time_list_event_type: '所要時間',
  i18n_use_standard_setting: 'テンプレート設定',
  i18n_use_standard_setting_tooltip:
    '事前にテンプレートで設定いただくと、設定いただいた内容にそって候補日時が自動でピックアップされます。よりスムーズにスケジュール調整できます。設定の編集を行う場合は「鉛筆マーク」をクリックし編集ください。',
  i18n_year: '年',
  i18n_schedule_finished_notification:
    '投票が確定しているので、現在この投票は編集できません。同様の設定内容で投票メニューをコピーしますか？',
  i18n_preview: 'プレビュー',
  i18n_monday: '月曜日',
  i18n_tuesday: '火曜日',
  i18n_wednesday: '水曜日',
  i18n_thursday: '木曜日',
  i18n_friday: '金曜日',
  i18n_saturday: '土曜日',
  i18n_sunday: '日曜日',
  i18n_calendar_monday: '日',
  i18n_calendar_tuesday: '月',
  i18n_calendar_wednesday: '火',
  i18n_calendar_thursday: '水',
  i18n_calendar_friday: '木',
  i18n_calendar_saturday: '金',
  i18n_calendar_sunday: '土',
  i18n_schedule_adjustment_intruction1: '内容をご確認の上、',
  i18n_schedule_adjustment_intruction2: 'ご都合のいい日時を選択ください。',
  i18n_schedule_adjustment_intruction_step3:
    '調整相手に連絡するあなたの情報を下記よりご入力ください。',
  i18n_schedule_adjustment_intruction_step_3:
    '調整相手に連絡する「あなたの情報」を下記よりご入力ください。',
  i18n_day_selection: '日付選択',
  i18n_time_selection: '時間選択',
  i18n_detail_info_step: '情報入力で完了',
  i18n_page_invite_member: '投票ページ',
  i18n_page_vote: '投票状況の確認',
  i18n_vote_overview_tooltip: `すでに投票したユーザーの投票内容を確認できます。\n
※投票日時で〇が一番多い日時の背景が「緑色」になっています。`,
  i18n_image: '写真',
  i18n_meeting_formality: 'ミーティング形式',
  i18n_meeting_method: 'ミーティング場所',
  i18n_label_name: 'お名前',
  i18n_error_delete_event: '指定されたデータは無効でした。',
  i18n_required: '必須',
  i18n_email: 'メールアドレス',
  i18n_add_contact: '+ ゲストを追加',
  i18n_advance_setting: '詳細設定',
  i18n_random: '任意',
  i18n_contact_information: '連絡先（電話番号やSkype IDなど）',
  i18n_comment_description:
    'ここで入力した内容が主催者とゲストに送信されます。',
  i18n_content_label: '内容',
  i18n_set: '設定',
  i18n_set_schedule: '日程設定',
  i18n_working_time: '勤務時間',
  i18n_working_time_tooltip:
    'こちらで設定した勤務時間が「予約受付可能な時間帯」になり、カレンダーにも反映されます。日程ごとに調整したい場合は、アカウント設定画面にて調整ください',
  i18n_time_to_start_welcome: '受付開始時間',
  i18n_time_to_start_welcome_tooltip:
    '予約を開始する時間を設定できます。 \n例）受付開始時間を3時間で設定した場合\n現在時刻が10月20日14:00の場合、 \n10月20日17:00以降から予約が可能になります。',
  i18n_min_vote: '最低投票数',
  i18n_select_min_vote:
    '投票相手が投票する際に、必要な最低投票数を設定できます。日程調整相手が選択した日数がこれより少ない場合、アラートが表示されます。\n' +
    '例：最低投票数を3つで設定した後、投票相手が1つで進めると、アラートを表示され日程調整する上で大変便利です。',
  i18n_ordered_amount: ' 予約日程の候補数',
  i18n_ordered_amount_tooltip: '候補日程を抽出する上限数を設定できます。',
  i18n_break_time: '休憩時間',
  i18n_break_time_tooltip:
    '予約が連続で入らないように、間隔を設定できます。例）休憩時間が15分の場合 １：09:00〜10：00に予約が入る ２：10：00〜10：15は予約が不可能（ミーティングの候補日時として抽出されない）になります。',
  i18n_snap: '昼食休憩',
  i18n_snap_tooltip:
    '設定した時間帯は、予約が不可能になり、候補日程としても「選択対象外」になります。',
  i18n_stage: '期間',
  i18n_stage_tooltip:
    '何日分の日程を表示するか設定できます。例）7日分を表示 ８日以降は非表示になります。',
  i18n_time_to_stop_welcome: '受付終了時間',
  i18n_time_to_stop_welcome_tooltip:
    '予約ができなくなる期限を設定できます。例）受付終了時間が1時間の場合 10月20日15:00開始の予約は、10月20日14:00を過ぎると予約が不可能になります。',
  i18n_time_setting: '時間設定',
  i18n_time_setting_tooltip:
    '設定した選択時間帯を優先し、「候補日程自動作成」時に、候補日程として作成されます。',
  i18n_priority: '優先順位',
  i18n_message_setting: 'メッセージ設定',
  i18n_message_setting_intruction:
    'ゲストに表示する名前やメッセージを設定します。',
  i18n_message_announced_when_start_adjust:
    '日程調整開始時に表示するメッセージ',
  i18n_message_announced_when_start_adjust_tooltip:
    '日程調整相手ページの「コメント」欄に表示するメッセージを設定できます。日程調整相手にお伝する内容がある場合に、こちらにメッセージをご入力ください',
  i18n_message_announced_when_stop_adjust: '日程調整終了時に表示するメッセージ',
  i18n_message_announced_when_stop_adjust_tooltip:
    '日程調整相手ページの「〇〇様からのコメント」欄に表示するメッセージを設定できます。日程調整相手にお伝する内容がある場合に、こちらにメッセージをご入力ください。',
  i18n_message_announced_when_start_adjust_placeholder:
    '打ち合わせはオンラインにてよろしくお願いいたします。',
  i18n_message_announced_when_stop_adjust_placeholder:
    'ご確認ありがとうございました。よろしくお願いいたします。',
  i18n_meeting: 'ミーティング',
  i18n_create_button: '新規作成',
  i18n_schedule_auto: '候補日程自動制作',
  i18n_schedule_auto_personal: 'テンプレート設定',
  i18n_name: '名前',
  i18n_register_title: '新規会員登録（無料）',
  i18n_free_trial: '今なら2週間お試し無料！',
  i18n_signup_charge_descript: '※お申込みなく課金することはありません。 ',
  i18n_signup_other_method:
    '※Google・Microsoftのアカウントで簡単に登録いただけます。',
  i18n_signup_other_method_mobile_1: '※Google・Microsoftのアカウントで',
  i18n_signup_other_method_mobile_2: '簡単に登録いただけます。',
  i18n_signup_other_method_2:
    '登録アカウントのカレンダーと連携され、スケジュール調整が効率化できます。',
  i18n_signup_google: 'Googleアカウントで登録',
  i18n_signup_office: 'Microsoftアカウントで登録',
  i18n_signin_google: 'Googleアカウントでログイン',
  i18n_signin_office: 'Microsoftアカウントでログイン',
  i18n_signup_link_login: '登録済みの方はこちら',
  i18n_btn_login: 'ログイン',
  i18n_signup_term: '利用規約',
  i18n_signup_accept_text: ' に同意します',
  i18n_contract_term: '契約期間',
  i18n_added_account: '追加アカウント数',
  i18n_contract_type: '契約種別',
  i18n_contract_type_placeholder: '契約種別を選択ください。',
  i18n_used_money_not_included_tax: 'ご利用金額（税抜）',
  i18n_used_money_included_tax: 'ご利用金額（税込）',
  i18n_credit_card: 'クレジットカード',
  i18n_turn_back: 'もどる',
  i18n_payment_confirm: '決済する',
  i18n_change: '変更',
  i18n_contract_detail_confirm: '契約内容確認',
  i18n_contract_detail_confirm_reminder:
    '以下の契約内容でお間違いないかご確認ください。',
  i18n_day_on: 'オン',
  i18n_day_off: '終日オフ',
  i18n_delete_event: '削除',
  // i18n_minute: '分',
  i18n_copied: 'コピー完了',
  i18n_required_text: '必須項目のため、入力をお願いします。',
  i18n_team_name: 'チーム名',
  i18n_event_name: 'イベント名',
  i18n_input_all_white_space: '{validateField}を入力してください',
  i18n_validate_field_name_limit_255_characters:
    '{validateField}は255文字以内で入力してください。',
  i18n_required_url: '半角英数字にて入力ください。',
  i18n_format_text: "半角英字、数字、 '-', '_'記号のみが利用可能です。",
  i18n_breakdown: '内訳',
  i18n_embed_in_website: 'WEBサイトに埋め込む',
  i18n_event_time: 'イベント時間',
  // i18n_move_time: '移動',
  i18n_move_time: '移動時間',
  i18n_move_time_double: '往復移動時間',
  i18n_edit: '編集',
  i18n_copy: 'この設定をコピー',
  i18n_copy_link_to_email: 'メール貼り付け用リンク',
  i18n_copy_schedule_date: 'リンクなし日程コピー',
  i18n_once_link: '一回利用 URL',
  i18n_many_time_link: '複数回利用URL',
  i18n_many_time_link_personal: '複数回利用URL',
  i18n_confirm_content: '内容確認',
  i18n_cancel_done: 'キャンセル完了',
  i18n_cancel_booking: '予約のキャンセル',
  i18n_cancel_comment_note:
    'ここで入力した内容が主催者とゲストに送信されます。',
  i18n_confirm_cancel: '予約をキャンセルする',
  i18n_return_home: 'ホームに戻る',
  i18n_member_invite: 'メンバー招待',
  i18n_add_account: 'アカウント追加',
  i18n_all_account: '全てのアカウント',
  i18n_activity: 'アクティブ',
  i18n_checking: '確認中',
  i18n_monthly_contract: '月契約',
  i18n_available_amount: '使用可能数',
  i18n_active_times: 'アクティブ数',
  i18n_contract_account_amount: '契約アカウント数',
  i18n_account_status: 'アカウント状況',
  i18n_annual_contract: '年契約',
  i18n_member: 'メンバー',
  i18n_change_role: '権限変更',
  i18n_manager: '管理者',
  i18n_authority: '権限',
  i18n_tab_account_vote_tooltip:
    '1：1での日程調整が可能です。\n' +
    '連携いただいているカレンダーをもとにご都合の良い日時を自動抽出・手動抽出でき日程調整を効率化できます。\n' +
    'URLを調整相手に送るだけで日程調整できます。\n' +
    '具体例：①取引先との1：1のミーティング、ランチミーティング、会食（A社・B社の2社で日程調整する）\n' +
    '②チーム単位での取引先とのミーティング、ランチミーティング、会食\n' +
    '③採用候補者との面接\n' +
    '④社内チームでの1on1ミーティング\n' +
    '\n' +
    'また、チーム単位での複数社と日程調整することも可能です。\n' +
    '例：A社の社員Bさん・Cさん・DさんをチームEとします。チームEがクライアントである法人F社・法人G社の3社間で日程調整する。\n' +
    '※Bさん・Cさん・Dさん・法人F社・法人G社での日程調整が可能です。\n' +
    '※チーム単位での日程調整は個人プランの場合、「プレミアムプラン」の限定機能が必要になります。（２週間は無料でご利用いただけます。）',
  i18n_authority_placeholder: '権限を選択ください。',
  i18n_payment: 'お支払い',
  i18n_payment_credit_card: 'クレジットカード払い',
  i18n_payment_invoice: '請求書払い',
  i18n_contract_descript:
    '※上記は、１アカウントごとにかかる月額料金（税抜）です。 ',
  i18n_credit_cart: 'クレジットカード情報入力',
  i18n_card_number: 'カード番号',
  i18n_card_number_placeholder: '例) 1234 5678 9012 3456',
  i18n_card_expiration_date: '有効期限',
  i18n_card_expiration_date_tooltip:
    '月は２桁（例：1月の場合→01）、年は２桁（例：2021年の場合→21）でご入力ください。',
  i18n_card_expiration_date_placeholder: '例) 11',
  i18n_card_expiration_date_placeholder_2: '例) 2022',
  i18n_card_security_code: 'セキュリティーコード',
  i18n_card_security_code_tooltip: '説明画面に該当する情報をご入力ください。',
  i18n_card_security_code_placeholder: '例) 123',
  i18n_confirm: '確認ページへ',
  i18n_add_member: 'メンバー追加 ',
  i18n_error_email_invite:
    '無効なメールアドレスですので、再度ご確認をお願いします。',
  i18n_success_email_invite: '招待メールを送信完了しました。',
  i18n_step_add_member_description_1:
    'STEP 1. 下記3点をご対応の上、「招待メール送信」ボタンを選択ください。連携したいメンバーへの「招待メール」が送信されます。',
  i18n_step_add_member_description_2:
    'STEP 2.「 招待メールを受けたメンバー」は、メールに記載されているリンクよりアカウント登録をお願いします。メンバーがアカウント連携を完了すると、自動で紐づけ完了（アクティブ状態）になります。',
  i18n_add_member_description_2: '①追加するメンバーのメールアドレスの入力',
  i18n_add_member_description_3: '②権限の選択',
  i18n_add_member_description_4:
    '③契約種別の選択 ※複数ユーザーへの一括送信が可能です。',
  i18n_add_member_description_5: '紐づけ完了（アクティブ状態）になります。',
  i18n_go_to_invitation_page: '招待ページに進む',
  i18n_confirm_contract_detail: '契約内容確認 ',
  i18n_required_notice: '上記より選択ください。',
  i18n_update: '設定 ',
  i18n_custom_required_time: 'その他の所要時間の設定',
  i18n_custom_travel_time: 'その他の移動時間の設定',
  i18n_custom_other: '「ミーティング場所」をご入力ください。',
  i18n_custom_phone: '「電話番号」をご入力ください。',
  i18n_custom_location: '対面の設定',
  i18n_custom_location_create_event_type:
    '「ミーティングをする住所」をご入力ください。',
  i18n_custom_location_other: '「ミーティング場所」をご入力ください。',
  i18n_invoice_description_title:
    '請求書払いをご希望の方は、下記URLの「お問い合わせ」よりお問い合わせください。',
  i18n_invoice_payment: '※請求書払いは ',
  i18n_invoice_payment_1: '①年契約の場合、1アカウント以上　もしくは ',
  i18n_invoice_payment_2:
    '②月払いの場合、5アカウント以上のみ対応可能となります。',
  i18n_invoice_payment_3: '申し訳ありませんが予めご了承ください。',
  i18n_inquiry_form: 'お問い合わせフォーム',
  i18n_back_to_homepage: 'ホームにもどる',
  i18n_send: '送信',
  i18n_send_email: 'メール送信',
  i18n_name_placeholder: '例) 山田太郎',
  i18n_phone_number: '電話番号',
  i18n_phone_number_placeholder: '例) 090-1234-5678',
  i18n_company_name_placeholder: '例) EVERGREEN株式会社',
  i18n_content_inquiry: 'お問い合わせ内容',
  i18n_content_inquiry_placeholder:
    '例) 請求書払いを希望のため、手続きを進めてください。',
  i18n_error_vote_event: 'イベントの日時を選択してください',
  i18n_error_at_least_1_user_vote:
    '少なくとも1人が投票しないと、日程決定はできません。',
  i18n_email_error_notice: 'メールアドレスの形式が正しくありません',
  i18n_jp_format_date: '{year}年{month}月{date}日',
  i18n_jp_format_date_month_day: '{month}月{date}日',
  i18n_email_guest: 'ゲストのメールアドレス',
  i18n_vote_tooltip_login:
    'タイムマッチに登録すると、登録いただいたカレンダーの予定が下記カレンダーに表示されるので大変効率的に日程調整していたけます。\n' +
    '※Google カレンダーや Microsoft（Outlook）のカレンダーを都度確認いただく必要がなく大変便利です。\n' +
    '「無料」で新規会員登録していただけますのでぜひお試しください。',
  i18n_error_vote_limit_day: `{nameCreaterEvent}様は{minVoteNumber}個以上の候補日程の選択をご希望されていますが、ご希望の選択数に足りておりません。このまま進めてよろしいでしょうか？`,
  i18n_calendar_category_description: '詳細なスケジュールは下記となります。',
  i18n_event_time_short: 'イベント',
  i18n_google_meet_url: 'Google Meet URLはこちら',
  i18n_microsoft_teams_url: 'Microsoft Teams URLはこちら',
  i18n_payment_complete: 'お支払い完了 ',
  i18n_payment_thanks: 'ご購入ありがとうございます！',
  i18n_payment_redirect_add_account:
    '招待ページにて、アカウントを利用するメンバーを追加してください。',
  i18n_payment_cancel_booking_complete_title: '予約をキャンセルしました',
  i18n_send_completely: '送信完了 ',
  i18n_thanks_for_inquiry: 'お問い合わせいただきありがとうございました。',
  i18n_thanks_for_inquiry_2:
    '24時間以内に担当から折り返しご連絡させていただきます。',
  i18n_thanks_for_inquiry_3: '何卒よろしくお願いいたします。',
  i18n_invitation_email_sent: '招待メール送信完了 ',
  i18n_invitation_email_sent_1: '招待メールの送信が完了しました。',
  i18n_invitation_email_sent_2:
    'ユーザーページよりステータスをご確認いただけます。',
  i18n_redirect_user_page: 'ユーザーページへ',
  i18n_return: '戻る',
  i18n_add_url_placeholder: 'あなたのURLをご入力お願いします',
  i18n_start_time_placeholder: '開始時刻',
  i18n_end_time_placeholder: '終了時刻',
  i18n_title_delete_event_type: '削除しますか？',
  i18n_cancel_delete: 'いいえ',
  i18n_confirm_delete: 'はい',
  i18n_confirm_delete_event: 'はい',
  i18n_confirm_ok: '完了',
  i18n_used_link_notice: 'こちらのURLが既に使われています',
  i18n_pls_contact_URL_owner: 'URLの所有者にご連絡ください',
  i18n_booking_success_notice: 'ご予約を受け付けました',
  i18n_this_time_already_booked: '選択した時間帯は利用不可能です',
  i18n_notice_modal_title: '通知',
  i18n_here: 'こちら',
  i18n_zoom_not_connected: 'Zoom連携されていません。',
  i18n_to_connect_zoom: 'Zoomアカウント連携は',
  i18n_google_meet_not_connected: 'Googleアカウント連携されていません。',
  i18n_to_connect_google_meet: 'Googleアカウント連携は',
  i18n_microsoft_teams_not_connected: 'Microsoft Teams連携されていません。',
  i18n_to_connect_microsoft_teams: 'Microsoft Teamsアカウント連携は',
  i18n_account_settings: 'アカウント設定',
  i18n_user_manager: 'ユーザー',
  i18n_change_user_manager: 'ユーザー管理',
  i18n_schedule_setting: 'スケジュール簡単設定 ',
  i18n_schedule: 'スケジュール',
  i18n_invite_member: 'メンバー招待',
  i18n_cooperate: 'サービス連携',
  i18n_account_purchase: 'アカウント購入',
  i18n_contract_and_payment: '契約内容確認・ご請求',
  i18n_logout: 'ログアウト',
  i18n_update_password: 'パスワード変更',
  i18n_cancel_text: 'キャンセル',
  i18n_confirm_logout: 'ログアウトしますか？',
  i18n_move_time_more_than_required_time_error:
    '移動時間を超える所要時間を入力してください',
  // i18n_invalid_url: '無効なカレンダーURLです',
  i18n_invalid_url: 'この日程調整用のURLは使用済みのためご利用いただけません。',
  // i18n_login_to_set_valid_url_1: 'アカウントオーナーの方は',
  i18n_login_to_set_valid_url_2: 'ログイン',
  // i18n_login_to_set_valid_url_3: 'し、有効にすることができます',
  i18n_login_to_set_valid_url_3:
    '日程調整をご希望の場合は、URL送信者（タイムマッチご利用者）の方へご連絡をお願い致します。',
  i18n_connect_to_zoom_success: 'Zoomと連携完了しました',
  i18n_connect_to_google_meet_success: 'GoogleCalendarとの連携が完了しました',
  i18n_connect_to_microsoft_team_success: 'MicrosoftTeamsと連携完了しました',
  i18n_sync: '連携',
  i18n_term_of_user: 'タイムマッチ利用規約',
  i18n_privacy_policy: 'プライバシーポリシー',
  i18n_book_calendar: '予約',
  i18n_contract_by_month: '{price}円/月契約',
  i18n_contract_by_year: '{price}円/年間契約',
  i18n_contract_endow_note: '年間{lowPrice}円お得です',
  i18n_by_trial: '無料',
  i18n_by_month: '月額',
  i18n_by_year: '年額',
  i18n_state_by_month: '本契約（月）',
  i18n_state_by_year: '本契約（年）',
  i18n_card_expiration_placeholder: '例）01/22',
  i18n_invalid_card_info: '必須項目のため、入力をお願いします',
  i18n_create_transaction_failed: 'クレジット決済に失敗しました',
  i18n_user_info_booking: 'ミーティング主催者',
  i18n_user_info_booking_b: '日程調整相手の情報',
  i18n_comment_from: 'からのコメント',
  i18n_wrong_phone_number: '半角数字のみが入力可能です',
  i18n_register_new_free_acc: '新規会員登録（無料）',
  i18n_confirm_password_wrong: 'パスワードが一致していません',
  i18n_wrong_password_length:
    '半角英大文字・小文字・数字混合で8文字以上の入力をお願いします。',
  i18n_account_already_existed:
    '入力されたメールアドレスは既に登録されているため、利用できません',
  i18n_register_success: 'メールで会員登録の確認が完了しました ',
  i18n_confirm_delete_account: '削除しますか？',
  i18n_choose_available_time: '日付/時刻が解釈できません',
  i18n_reset_password: 'パスワード再設定',
  i18n_please_fill_info: '下記情報をご入力ください。',
  i18n_please_check_your_email: '認証メールを送信しますのでご確認ください',
  i18n_email_not_existed: '入力されたメールアドレスが存在しません',
  i18n_change_password_success: 'パスワード変更が完了しました',
  i18n_password: 'パスワード',
  i18n_confirm_password: 'パスワードの確認',
  i18n_use_recommend_password: '提案されたパスワードを使用',
  i18n_auto_login_then: '次回から自動ログインする',
  i18n_if_forgot_pass_click_here: 'パスワードを忘れた方はこちら',
  i18n_valid_pass_length: '※半角英大文字・小文字・数字混合で8文字以上',
  i18n_register_free: '無料登録する',
  i18n_you_will_recieve_mail: '会員登録認証メールが届きます。',
  i18n_require_accept_term:
    '「利用規約・プライバシーポリシーに同意します」のチェックボックスにチェックを入れてください。',
  i18n_email_placeholder: 'メールアドレスをご入力ください。',
  i18n_invalid_email: '入力されたメールは正しくありません',
  i18n_delete_account_success: 'アカウント削除が完了しました',
  i18n_delete_account_failed: 'アカウント削除が失敗しました',
  i18n_update_account_success: 'アカウント情報の変更が完了しました',
  i18n_adjusted_schedule: '日程調整完了',
  i18n_register_to_google_calendar: 'Googleカレンダーに登録',
  i18n_register_to_microsoft_365: 'Microsoftに登録',
  i18n_register_as_new_member_free: 'かんたん新規会員登録（無料）',
  i18n_service_site: 'サービスサイト',
  i18n_upload_image: '写真をアップロードする',
  i18n_full_name: '氏名',
  i18n_user_name: '表示名(アカウント名)',
  i18n_btn_save: '保存',
  i18n_btn_delete_linked: '解除する',
  i18n_btn_delete_account: '削除する',
  i18n_calendar_url: 'カレンダーのURL',
  i18n_modal_crop_title: '写真のサイズを調整',
  i18n_update_profile_success: 'プロフィール情報更新が完了しました',
  i18n_update_profile_failed: 'プロフィール情報更新が失敗しました',
  i18n_profile: 'プロフィール',
  i18n_delete: '削除する',
  i18n_account_unlinkage: 'アカウントの連携解除',
  i18n_account_delete: 'アカウントの削除',
  i18n_delete_account_confirm: '解除しますか？',
  // i18n_confirm_delete: 'はい',
  i18n_confirm_not_delete: 'いいえ',
  i18n_send_invitation_email: '招待メール送信',
  i18n_registration: '登録',
  i18n_send_email_invitation_success: 'メンバーの招待メールを送信完了しました',
  i18n_login_with_other_mail: 'Google/Microsoftアカウント以外でログイン',
  i18n_signup_with_other_mail: 'Google/Microsoftアカウント以外で登録',
  i18n_custom: 'カスタム',
  i18n_custom_setting: 'カスタム設定',
  i18n_custom_min_vote: 'カスタム',
  i18n_custom_period: 'カスタム',
  i18n_custom_reservation: 'カスタム',
  i18n_custom_descript_title: '※半角数字を入力ください。',
  i18n_schedule_custom_value: 'ボタンを押して、お好みの時間・分に選択の上、',
  i18n_schedule_custom_value_2: '「決定」ください。',
  i18n_required_fields: '入力の上で保存ください。',
  i18n_zoom_integration_title: 'Zoom 連携のメリット',
  i18n_detail_explaination: '詳しい説明',
  i18n_google_meet_integration_title: 'Google Meet 連携のメリット',
  i18n_microsoft_teams_integration_title: 'Microsoft Teams 連携のメリット',
  i18n_work_together: '連携する',
  i18n_zoom: 'Zoom',
  i18n_google_meet: 'Google Meet',
  i18n_microsoft_teams: 'Microsoft Teams',
  i18n_already_linked: '既に連携されました',
  i18n_404_not_found: 'お探しのページが見つかりません',
  i18n_please_select_event_type:
    '下記よりご希望のミーティング方法を選択ください。',
  i18n_booking_schedule_from: '様からの日程調整依頼',
  i18n_select: '選択する',
  i18n_please_accept_mail_from: 'からのメールを受信許可してください。',
  i18n_add_member_email_error: 'メールアドレスをご入力ください。',
  i18n_add_member_contract_type_error: '契約種別を選択ください。',
  i18n_add_member_role_error: '権限を選択ください。',
  i18n_empty_row_value: 'アカウントを入力してください',
  i18n_forgot_password_error: 'パスワードが正しくありません。',
  i18n_reset: '再設定',
  i18n_password_validate_notice: 'パスワードが一致しません。',
  i18n_please_login_with_your_acc:
    '※ご登録済みのアカウントでログインしてください。',
  i18n_click_here_if_not_register: '会員登録がまだの方はこちら',
  i18n_register_as_new_member: '新規会員登録',
  i18n_email_error_1001: 'このメールアドレスは登録できません。',
  i18n_email_error_1002:
    '新規候補ユーザー {listEmail} は他管理者にて有料会員のため紐づけできません。上記の有料会員期間の終了後に再度「登録」をお願いします。',
  i18n_email_error_1003:
    '新規候補ユーザー {listEmail} はアカウント未連携のため「登録」できません。アカウント連携後に「登録」をお願いします。',
  i18n_email_error_1004:
    '新規候補ユーザー {listEmail} は他管理者にて有料会員のため紐づけできません。上記の有料会員期間の終了後に再度「登録」をお願いします。',
  i18n_empty_data_table: 'アカウント追加後にご利用いただけます。',
  i18n_update_card_title: 'クレジットカードの変更',
  i18n_update_card_description: '下記にてクレジットカードを変更いただけます。',
  i18n_change_payment_card_success: 'クレジットカードの変更に成功しました。',
  i18n_required_stripe_fields: '必須項目のため、入力をお願いします',
  i18n_error_card_number: 'カード番号に誤りがあります',
  i18n_error_card_expired_date: '有効期限に誤りがあります',
  i18n_error_card_cvc: 'セキュリティコードに誤りがあります',
  i18n_time_frame_error: '開始時間を終了時間より前の時間に設定ください。',
  i18n_delete_account: 'アカウント削除（契約解除）',
  i18n_empty_data: 'データがありません',
  i18n_will_not_release: 'いいえ',
  i18n_release: 'はい',
  i18n_unlinkage_confirm: '削除しますか？',
  i18n_cancel_integrates_success: 'アカウントの連携解除に成功しました。',
  i18n_booking_failed: '予約に失敗しました',
  i18n_register_email_success:
    '登録に成功しました。登録完了するには、メールアドレスを確認してください。',
  i18n_list_event_user: 'ホーム',
  i18n_schedule_adjustment: '日程調整する',
  i18n_by_application: '用途別',
  i18n_created_by_application1: '用途別',
  i18n_created_by_application2: '作成',
  i18n_by_pinpoint: 'ピンポイント',
  i18n_created_by_pinpoint1: 'ピンポイント',
  i18n_created_by_pinpoint2: '作成',
  i18n_event_type_no_name: 'イベント名なし',
  i18n_created_by_pinpoint: 'ピンポイント 作成',
  i18n_status: 'ステータス',
  i18n_number_of_accounts: 'アカウント数',
  i18n_this_account: '本契約',
  i18n_expect_billing_date: '請求予定日',
  i18n_fee: '料金',
  i18n_amount_include_tax: '金額(税込)',
  i18n_change_card: 'カード変更',
  i18n_invalid_phone_number: '電話番号の形式が正しくありません',
  i18n_contract_detail: '契約内容',
  i18n_next_billing: '次回請求',
  i18n_payment_method: 'お支払い方法',
  i18n_payment_history: 'お支払い履歴',
  i18n_tab_account_vote: '日程調整ページ',
  i18n_url_not_available: 'このURLは使用できません',
  i18n_email_not_registered: 'ユーザーが見つかりません',
  i18n_data_hollow: `予約受付ページがございません。
「新規作成」から作成いただき、日程調整を効率化ください。`,
  i18n_data_hollow1: `予約受付ページがございません。`,
  i18n_data_hollow2: `「新規作成」から作成いただき、日程調整を効率化ください。`,
  i18n_edit_team: '編集する',
  i18n_remove_team: '削除する',
  i18n_note_table_transactions:
    '※請求書払いの場合は、弊社との契約に基づいて上記と差異があることを予めご了承ください。',
  i18n_validate_text_half_size: '半角英字、数字のみが利用可能です。',
  i18n_sync_gg_calendar_success:
    '連携したカレンダーに、登録が完了しました。ミーティング情報などはカレンダーの説明欄にてご確認ください。',
  i18n_sync_microsoft_365_success:
    '連携したカレンダーに、登録が完了しました。ミーティング情報などはカレンダーの説明欄にてご確認ください。',
  i18n_create_party_event_type:
    '不特定多数の方にURLを送付する場合や、よく使うイベントにご活用ください。複数の候補日程を自動抽出されます。使用例）インサイドセールス、多数の候補日程を調整相手に送付したい',
  i18n_title_header_create_event_type: '日程調整ページ作成',
  i18n_step_1_in_preview: '内容確認',
  i18n_step_2_in_preview: '日時選択',
  i18n_step_1_in_vote: '候補日時の状況を選択',
  i18n_step_2_in_vote: '情報入力で完了',
  i18n_step_1_adjust: 'ご都合の良い日時を選択',
  i18n_step_2_adjust: '情報入力で完了',
  i18n_email_exist_account:
    '新規候補ユーザー {listEmail} は他管理者にて有料会員のため紐づけできません。上記の有料会員期間の終了後に再度「招待メール送信」をお願いします。',
  i18n_calendar_text_note_1: 'ご都合のいい日を下記より選択ください。',
  i18n_calendar_text_note_2:
    '※複数選択いただくと、調整先のお相手も日程調整がしやすくなります。',
  i18n_login_failed: 'アカウントにサインできません',
  i18n_enter_search_keyword: '検索したい内容をご入力ください。',
  i18n_enter_search_keyword_placeholder: 'キーワードで探す',
  i18n_search: '検索',
  i18n_contact_us: 'お問い合わせはこちら',
  i18n_online_briefing: '操作方法などのオンライン説明会を ご希望の方はこちら',
  i18n_max_day_select: '3日以上選択できません',
  i18n_add_new: '新規追加',
  i18n_search_by_keyword: 'キーワードで探す',
  i18n_check: '一括',
  i18n_employee_name: '社員名',
  i18n_paid_membership_period: '有料会員期間',
  i18n_authority_type: '権限種類',
  i18n_authority_type_line_2: '※管理者かメンバー',
  i18n_contract_type_line_2: '※月か年',
  i18n_amount: '金額',
  i18n_monthly_amount: '金額/月額',
  i18n_free_period_start: '無料期間開始',
  i18n_free_period_end: '無料期間終了',
  i18n_status_status: 'ステータス状況',
  i18n_business_flow: '商流',
  i18n_agency_in_case: '代理店の場合、代理店名',
  i18n_action: 'アクション',
  i18n_street_address: '住所',
  i18n_edit_free_period: '無料期間調整',
  i18n_update_information: '情報変更(金額変更含む)',
  i18n_update_expire_form: 'フォーム設定',
  i18n_validity_period_form: 'フォーム有効期間',
  i18n_start_time_form: '時間開始',
  i18n_end_time_form: '時間終了',
  i18n_invoicing: '請求書発行',
  i18n_suspense_of_use: '利用停止',
  i18n_delete_row_user: '削除',
  i18n_free_registration: '無料登録中',
  i18n_this_contract_year: '本契約（年）',
  i18n_this_contract_month: '本契約（月）',
  i18n_invoice: '請求書',
  i18n_direct_sales: '直販売',
  i18n_agency_sales: '代理店販売',
  i18n_csv_download: 'CSVダウンロード',
  // i18n_here: 'こちら',
  i18n_delete_user_success: 'ユーザーを削除しました。',
  i18n_how_to_use: 'タイムマッチの使い方',
  i18n_QA_question_1: 'カレンダーの基本的な使い方 ',
  i18n_QA_answer_11: '・カレンダー作成ページ',
  i18n_QA_answer_12: '・ダッシュボード\n（用途別・ピンポイント）',
  i18n_QA_answer_13: '・テンプレートに関して',
  i18n_QA_answer_14: '・候補日程自動設定',
  i18n_QA_answer_15: '・予定の確認・キャンセルの方法\n（予約一覧）',
  i18n_QA_answer_16: '・予約の方法（日程調整ページ）',
  i18n_QA_answer_17: '・日程調整相手の対応内容',
  i18n_QA_question_2: 'アカウント設定 ',
  i18n_QA_answer_21: '・アカウント連携・ログイン\n（Googleアカウント）',
  i18n_QA_answer_22: '・アカウント連携・ログイン\n（Microsoftアカウント）',
  i18n_QA_answer_23: '・メールでの登録、ログイン',
  i18n_QA_answer_24: '・初期設定\n（URL、デフォルト時間の設定）',
  i18n_QA_answer_25: '・パスワードリセット',
  i18n_QA_question_3: '連携 ',
  i18n_QA_answer_31: '・Zoom連携',
  i18n_QA_answer_32: '・Google Meet連携 ',
  i18n_QA_question_4: 'より詳細な設定',
  i18n_QA_answer_41: '・詳細設定',
  i18n_QA_answer_42: '・候補日程自動作成',
  i18n_QA_answer_43: '・ダッシュボード',
  i18n_QA_question_5: 'アカウント設定とオプション',
  i18n_QA_answer_51: '・プロフィール設定方法',
  i18n_QA_answer_52: '・請求（アカウント購入）',
  i18n_QA_answer_53: '・メンバーの追加方法について',
  i18n_QA_answer_54: '・請求書払いについて',
  i18n_QA_answer_55: '・契約内容確認ページの見方',
  i18n_QA_answer_56: '・アカウント状況ページの見方',
  i18n_QA_question_6: 'お支払い ',
  i18n_QA_answer_61: '・料金 ',
  i18n_QA_answer_62: '・支払い方法',
  i18n_see_all: '全てみる',
  i18n_update_card: '変更する',
  i18n_cannot_delete_owner: '最低１名は「管理者」が必要のため変更できません。',
  i18n_run: '実行する',
  i18n_select_operation_placeholder: '操作を選択する',
  i18n_personal_in_charge: '担当者名',
  i18n_secondary_agency: '2次代理店',
  i18n_header_preview: '様から日程調整依頼です',
  i18n_header_admin: 'から日程調整依頼',
  i18n_account_management: 'アカウント管理',
  i18n_list_of_agencies: '代理店一覧',
  i18n_list_of_acccount: 'アカウント一覧',
  i18n_agency_management: ' 代理店管理',
  i18n_required_select_field: '必須項目のため、選択をお願いします',
  i18n_create_agency_success: '代理店を作成しました。',
  i18n_invalid_free_period_start: '開始日付は未来日付を設定してください',
  i18n_invalid_free_period_end: '開始日付を終了日付前に選択してください。',
  i18n_create_account_success: 'アカウントを作成しました',
  i18n_expired_free_title: '有料登録のお願い ',
  i18n_expired_free_text_1: 'ご利用いただきありがとうございます。',
  i18n_expired_free_text_2: '無料トライアルが終了しましたので、',
  i18n_expired_free_text_3: '下記より有料登録いただけますでしょうか。',
  i18n_register_for_fee: '有料登録する',
  i18n_view_service_site: 'サービスサイトをみる',
  i18n_team_title: 'チーム編集',
  i18n_label_name_team: 'チーム名',
  i18n_label_team_member: 'チームメンバー',
  i18n_go_back: '戻る',
  i18n_create_team_send: 'チーム作成完了',
  i18n_create_team_send_edit: '編集完了',
  i18n_name_team_placeholder: '例: チーム 1',
  i18n_management_authority_calendar: '該当チームカレンダーの管理権限',
  i18n_action_team_member: '「必須」「または」「不参加」を選択',
  i18n_role_not: 'なし',
  i18n_role_correct: 'あり',
  i18n_status_required: '必須',
  i18n_status_or: 'または',
  i18n_status_non: '不参加',
  i18n_error_add_member_field:
    '追加したユーザーはアカウントメンバーではありません。チームメンバーを追加できません。',
  i18n_error_add_member_role_hollow: '「あり」「なし」から1つ選択ください。',
  i18n_error_add_member_type_hollow:
    '「必須」「または」「不参加」より1つ選択ください。',
  i18n_management_authority_calendar_tooltip:
    '当該チームのメンバーごとの編集権限を設定できます。\n' +
    '「あり」に設定したメンバーは、チームメンバーの編集やチームの日程調整ページの作成・編集・削除が可能になります。\n' +
    '一方で、「なし」に設定したメンバーは、チームメンバーの編集やチームの日程調整ページの作成・編集・削除ができなくなります。',
  i18n_action_team_member_tooltip: `チームのメンバーごとでのミーティングへの参加条件を設定できます。\n
①「必須」→出席必須なメンバーは「必須」を選択ください。\n
例：AとBが参加メンバーで「必須」を設定。AとBの両方が参加日程が選択されます。\n
②「または」→選択したメンバーのうち、1名が参加できる候補日程を選択されます。\n
例；AとBが参加メンバーで「または」を設定。AもしくはBのどちらかが参加できる候補日程が選択されます。
実施企業例：営業メンバーが複数参加するチームを作成。営業メンバーの内で１名が参加できればいい候補日程が選択されます。\n
※１つのチーム設定で「必須」と「または」は併用できません。
\n
③「不参加」→ミーティングには参加しないが、日程調整するかたに「不参加」を設定ください。\n
例：秘書や営業アシスタントによるミーティング参加者の日程設定、インサイドセールスメンバーによるフィールドセールスメンバーの日程調整`,
  i18n_update_template_1: 'テンプレート１に登録',
  i18n_update_template_2: 'テンプレート２に登録',
  i18n_update_template_3: 'テンプレート３に登録',
  i18n_title_vote_one: 'ミーティング内容の確認',
  i18n_title_vote_tow: '日程選択',
  i18n_vote_note: '※ご都合の良い日程は〇を選択してください',
  i18n_vote_tooltip: `投票において日程調整するために、
  ①参加可能な日時は「○」
  ②参加不可な日時は「×」
  ③参加できるか未定な日時は「△」
  を選択してください。`,
  i18n_vote_tooltip_confirm:
    'リアルタイムで投票相手の投票状況を確認できます。「〇」が多い日程の背景が緑色になります。投票状況からミーティング日程を決定するために「日程選択」をクリックください。',
  i18n_coordination_between_companies: '複数間での調整',
  i18n_coordination_between_companies_design: '社間以上の調整',
  i18n_time_meeting: '所要時間',
  i18n_vote_owner: '主催者',
  i18n_update_template_successfully: '基本設定として更新しました。',
  i18n_click_to_upload: 'CSVファイルをアップロード',
  i18n_upload_file_csv: 'アップロード',
  i18n_upload_file_csv_failed: 'CSVファイルをアップロードに失敗しました。',
  i18n_upload_file_csv_success: 'CSVファイルをアップロードしました。',
  i18n_confirm_suspense_account: '利用停止しますか。?',
  i18n_activation: 'アクティブ化',
  i18n_update_users_success: 'ユーザー情報の変更に成功しました',
  i18n_email_already_existed: 'アカウントが存在しています。',
  i18n_account_is_not_existed: 'アカウントが登録されていません。',
  i18n_update_default_time_error: '自由時間を選択してください',
  i18n_update_default_time_success: '保存しました。',
  i18n_update_default_settings: '調整日時(勤務時間)',
  i18n_end_free_membership_registration: '利用停止（無料会員登録終了）',
  i18n_termination_contract: '解約（本契約終了）',
  i18n_message_invalid_syntax_number: '半角数字を入力ください。',
  i18n_error_message_lunch_time:
    '開始時間を終了時間より前の時間に設定ください。\n※例：12:00 - 13:00',
  i18n_error_message_default_time:
    '開始時間を終了時間より前の時間に設定ください。\n※例：09:00 - 18:00',
  i18n_error_message_priority_time:
    '開始時間を終了時間より前の時間に設定ください。\n※例：15:00－16:00',
  i18n_incomplete_card_number: '必須項目のため、入力をお願いします',
  i18n_incomplete_card_expiry: '4桁でご入力ください。',
  i18n_incomplete_card_cvc: 'セキュリティコードは3桁でご入力ください。',
  i18n_invalid_card_number: 'カード番号に誤りがあります',
  i18n_invalid_card_expiry_year: '有効期限に誤りがあります',
  i18n_invalid_card_expiry_year_past: '有効期限に誤りがあります',
  i18n_invalid_card_expiry_month: '有効期限に誤りがあります',
  i18n_incorrect_card_cvc: 'セキュリティコードに誤りがあります',
  i18n_login_admin_title: '管理者ログイン',
  i18n_google_relogin:
    'Googleのカレンダー連携期限が切れました。 この機能を使用するにはもう一度連携してください。',
  i18n_microsoft_relogin:
    'Microsoftのカレンダー連携期限が切れました。 この機能を使用するにはもう一度連携してください。',
  i18n_default_start_time_placeholder: '例: 9:00',
  i18n_default_end_time_placeholder: '例: 18:00',
  i18n_reception_start_placeholder: '例: 12時間',
  i18n_relax_time_placeholder: '例: 15分',
  i18n_lunch_start_placeholder: '例: 12:00',
  i18n_lunch_end_placeholder: '例: 13:00',
  i18n_period_placeholder: '例: 4週間',
  i18n_reservation_placeholder: '例: 10候補',
  i18n_min_vote_placeholder: '例: 1候補',
  i18n_hour: '時間',
  i18n_minute: '分',
  i18n_weekly: '週間',
  i18n_candidate: '候補',
  i18n_votes: '候補',
  i18n_invalid_price: '適切な金額を入力してください。',
  i18n_agent_selling_price: '代理店販売価格',
  i18n_wholesale_price: '代理店卸価格',
  i18n_update_agency_title: '代理店情報の編集',
  i18n_update_agency_success: '代理店情報を変更しました。',
  i18n_current_password: '現在のパスワード',
  i18n_new_password: 'パスワード',
  i18n_update_admin_password_success: 'パスワードを変更しました。',
  i18n_passwords_not_match: 'パスワードの確認が正しくありません。',
  i18n_update_agency: '情報変更',
  i18n_copy_time_success: 'コピーが完了しました',
  i18n_copy_latest_block_success: ' コピーが完了しました。',
  i18n_detail_time: '詳細時間',
  i18n_register_template:
    '上記の設定をテンプレートに登録される場合は、下記よりご登録ください。',
  i18n_suspense_of_use_status: '利用停止（無料会員登録終了）',
  i18n_cancellation: '解約（本契約終了）',
  i18n_paid_date: '支払期日',
  i18n_not_enough_slots: 'アカウント数が足りませんので、追加購入ください。',
  i18n_ok_button: 'OK',
  i18n_update_all: 'チェックされたアカウントを一致更新する',
  i18n_required_once_person: '最低１名は管理権限者が必要のため変更できません',
  i18n_description_delete_button_calendar:
    '当該ボタンを選択いただくと、表示されている候補日程が全て削除されます。その後、下記カレンダーから、希望日時箇所をクリックください。',
  i18n_name_delete_button_calendar: '予約枠を一括削除',
  i18n_method_to_create_date_and_time_for_calendar: '候補日時の作成方法',
  i18n_create_a_manual_calendar: '手動作成',
  i18n_create_a_auto_calendar: '自動作成',
  i18n_select_member: 'メンバー選択',
  i18n_select_member_tooltip:
    '選択したメンバーの日程がカレンダーに反映され、メンバーのスケジュールを参考にした上で、\n' +
    '「自動生成・手動生成」選択の上、候補日程の作成ができます。\n' +
    '\n' +
    '利用例：\n' +
    '①チームメンバー全員が都合のいい日時を選択したい\n' +
    '②チームメンバーで1人でも都合のいい日時を選択したい\n' +
    '③秘書による会社役員・マネージャー等の日程調整をしたい\n' +
    '②フリーランス・副業など複数のアドレスを利用する方が都合のいい日時を選択したい',
  i18n_add_member_calendar: '＋カレンダー追加',
  i18n_modal_add_calendar_team_x: '&times',
  i18n_modal_add_calendar_team_header_line_1: '表示するカレンダーを',
  i18n_modal_add_calendar_team_header_line_2: '選択して下さい',
  i18n_modal_add_calendar_team_content_line_1: '下記から選択したカレンダーが',
  i18n_modal_add_calendar_team_content_line_2: 'タイムマッチ上に表示されます。',
  i18n_modal_add_calendar_team_content_line_3:
    '※連携したカレンダーは許可しない限り、',
  i18n_modal_add_calendar_team_content_line_4:
    '自分以外に閲覧されることはありません。',
  i18n_note_vote: '任意',
  i18n_vote_time: '日時',
  i18n_vote_amount: '投票数',
  i18n_vote_you: 'あなた',
  i18n_modal_add_calendar_team_account_google: '連携するGoogleアカウントを選択',
  i18n_modal_add_calendar_team_account_microsoft:
    '連携するMicrosoftアカウントを選択',
  i18n_message_error_get_calendar_by_provider:
    'サーバーが何かエラーが発生しました。後で再試してください。',
  i18n_message_error_account_is_not_manager:
    'ご利用アカウントは個人アカウントです。個人アカウントはチーム・投票などを利用できません。',
  i18n_message_error_team_was_delete: 'チームは削除されました。',
  i18n_all_function_free: '標準機能は全て無料!',
  i18n_click_individual: '個人の方はこちら',
  i18n_click_corporation: '法人の方はこちら',
  i18n_signup_charge_descript_1:
    '※一部の追加機能は有料オブションを選択いただけます。',
  i18n_signup_charge_descript_1_mobile_1: '※一部の追加機能は有料オブションを',
  i18n_signup_charge_descript_1_mobile_2: '選択いただけます。',
  i18n_google_office365: 'Google/Office365',
  i18n_signup: 'アカウント以外で登録',
  i18n_signin: 'アカウント以外でログイン',
  i18n_individual: '※個人',
  i18n_corporation: '※法人',
  i18n_error_add_member_Title:
    'こちらよりオプション契約のお申し込みをお願いします。お申し込み後に御利用いただけます。',
  i18n_error_add_member_Descript:
    'オプション機能の無料お試し期間が終了しました。',
  i18n_step_1_invite_email_tooltip:
    '投票ページへのリンクが記載されたメールを送付することができます。 ※メールアドレスを正しく入力いメールアドレスの背景が「緑色」に変わります。 ※要確認',
  i18n_edit_delete_tooltip:
    '「編集ボタン」ボタンをクリックすると、当該ユーザーの設定を編集できます。 「ごみ箱」ボタンをクリックすると、選択したユーザーをチームメンバーから削除することができます。',
  i18n_step_1_invite_member_tooltip: `
    投票に参加するメンバーを招待できます。 日程調整したい相手に対して、メールまたはリンクで投票ページを共有することが可能です。
    `,
  i18n_step_1_invite_email_2_tooltip: `
    投票ページのURLをコピーできます。
    メールだけでなくチャットツールやSNS等にて 日程調整した相手にURLを送付ください。
    `,
  i18n_account_unlinkage_tooltip: `
    Google・Microsoftのアカウントとの連携を解除できます。
    解除すると、カレンダーへの自動追加やダブルブッキングの防止ができなくなるのでご注意ください。
    `,
  i18n_delete_account_tooltip: `
    アカウントのデータをすべて削除することができます。
    削除後に、セキュリティのためデータを削除しますので、
    アカウントの復旧はできないのでご注意ください。
    `,
  i18n_error_email_null: `メンバーを追加してください。`,
  i18n_suggest_and_footer_not_login_line_1: `新規登録・ログインいただくと、あなたの予定が`,
  i18n_suggest_login_line_1: `新規登録・ログインいただくと、`,
  i18n_suggest_login_line_2: `選択された予定があなたのカレンダーに`,
  i18n_suggest_login_line_3: `自動登録され便利です。`,
  i18n_footer_not_login_line_2: `上記カレンダーに反映され便利です。`,
  i18n_you_have_been_delete_from_team: 'あなたはチームから削除されました',
  i18n_cancel_plan: 'プラン解約',
  i18n_cancel_plan_tooltip: 'プラン解約',
  i18n_btn_cancel_plan: '解約する',
  i18n_cancel_plan_confirm: '本当にアカウントプランを解約しますか',
  i18n_add_member_send_email_title: 'メールでのリクエスト送信',
  i18n_add_member_send_email_body_1: '連携したいカレンダーの',
  i18n_add_member_send_email_body_2: 'メールアドレスを下記にご入力ください。',
  i18n_add_member_send_email_placeholder: '例）taro.tanaka@timematch.jp',
  i18n_add_member_send_email_button: 'リクエストメール送信',
  i18n_anonymous_member: 'アドレス名非公開',
  i18n_anonymous_event: '予定あり',
  i18n_expired_free_thank:
    'いつもタイムマッチをご利用いただきありがとうございます。',
  i18n_expired_free_member_1:
    '申し訳ございませんが、無料体験期間もしくは有料期間が終了したため、',
  i18n_expired_free_member_2: '現在タイムマッチをご利用いただけません。',
  i18n_expired_free_member_3:
    '「マスター権限（購入権限あり）」をお持ちの方より、',
  i18n_expired_free_member_4:
    'アカウント購入をいただきますようお願い申し上げます。',
  i18n_expired_free_member_5: 'またのご利用をお待ちしております。',
  i18n_expired_free_admin_slot_1:
    '申し訳ございませんが、無料体験期間もしくは有料期間が終了したため、',
  i18n_expired_free_admin_slot_2: '現在タイムマッチをご利用いただけません。',
  i18n_expired_free_admin_slot_3: '下記より有料登録いただけますでしょうか。',
  i18n_expired_free_admin_auto_1:
    '申し訳ございませんが、クレジットカードの自動決済が行われなかったため',
  i18n_expired_free_admin_auto_2: '現在タイムマッチをご利用いただけません。',
  i18n_expired_free_admin_auto_3:
    'クレジットカードの決済ができなかった理由は弊社ではわかりかねるため、',
  i18n_expired_free_admin_auto_4:
    '恐れ入りますがご利用のクレジットカード会社へお問い合わせください。',
  i18n_expired_free_admin_auto_5: '再度タイムマッチをご利用いただくためには、',
  i18n_expired_free_admin_auto_6:
    '別のクレジットカードをご用意のうえ下記よりお申し込みください。',
  i18n_dialog_cancel_button: 'キャンセル',
  i18n_dialog_ok_button: 'はい',
  i18n_setting_basic_step1_des: '会議を行う方法を選択してください',
  i18n_setting_basic_step2_des: '会議で使用するツールを選択してください',
  i18n_setting_basic_step3_des: '会議の時間を選択してください',
  i18n_setting_basic_step4_des: '候補日時の作成方法を選択してください',
  i18n_setting_basic_step5_des: '候補日時の作成方法を選択してください',
  i18n_setting_basic_option: '詳細設定',
  i18n_meeting_content: 'ミーティング内容',
  i18n_proceed_date_time_selection: '日時選択へ進む',
  i18b_3_day_before: '前の3日',
  i18b_3_day_after: '次の3日',
  i18n_step_1_adjust_mobile: '日時を選択',
  i18n_step_2_adjust_mobile: '情報入力で日程調整が完了',
  i18n_step_1_adjust_title_mobile: 'ご都合の良い日時を選択ください',
  i18n_step_2_adjust_title_mobile:
    'お名前・メールアドレスをご入力の上「完了する」をクリックください',
  i18n_suggest_and_footer_not_login_mobile:
    '新規登録（無料）・ログインであなたの予定をこのカレンダーに反映できます',
  i18n_suggest_and_footer_button: '新規登録\nログイン',
  i18n_calendar_title: 'カレンダー',
  i18n_fullname: '氏名',
  i18n_role: '役職',
  i18n_email_register: 'メールアドレス(アカウント)',
  i18n_privacy_policy_register: '個人情報の取扱について',
  i18n_privacy_policy_1_register: '同意する',
  i18n_btn_register: '無料会員登録する',
  i18n_today: '今日',
  i18n_calendar_link_other: 'カレンダを追加',
  i18n_calendar_link_other_title: '権限が必要な理由',
  i18n_calendar_link_other_content_1:
    'タイムマッチでは予定を見つつ候補を設定したり、 確定した予定を登録するために、カレンダー情 報の表示・編集・アクセスが必要となります。',
  i18n_calendar_link_other_content_2:
    'タイムマッチ上の予定作成や日程調整を除くカレン ダー情報はタイムマッチ内に保存されません。 また、第三者が許可なく連携したカレンダー情 報を閲覧・アクセスすることはできません。',
  i18n_calendar_link_other_google: 'Googleアカウントでログイン',
  i18n_calendar_link_other_microsoft: 'Microsoftアカウントでログイン',
  i18n_register_link: '利用登録は',
  i18n_email_registed_label: 'ご登録のメールアドレス',
  i18n_set_password_label: 'パスワードを設定',
  i18n_set_password_confirm_label: 'パスワードを設定(確認用)',
  i18n_password_register_title: 'パスワード登録',
  i18n_token_invalid_or_expired: 'トークンが無効または期限切れです。',
  i18n_password_not_match: 'パスワードが一致しません。',
  i18n_calendar_creation_title: '候補を提案',
  i18n_label_title: 'イベント名',
  i18n_title_placeholder: 'イベント名',
  i18n_label_meet_time: '打ち合わせ時間',
  i18n_auto_extract_candidate_btn: '候補を自動抽出',
  i18n_no_candidate: '候補はありません',
  i18n_label_other_info: 'その他の情報',
  i18n_label_adjust_type: '調整タイプ',
  i18n_one_person_meet: '１人調整',
  i18n_multi_person_meet: '数人調整',
  i18n_choose_candidate_please: '候補を選択してください',
  i18n_please_fill_in_all_fields: '全ての項目を入力してください',
  i18n_create_calendar_success_title: '調整方法を選択してください',
  i18n_create_calendar_success_content: '調整相手に候補を共有しましょう。',
  i18n_adjust_url_title: 'URLを共有',
  i18n_copy_adjust_url: '調整用URLをコピー',
  i18n_share_fixed_text_title: '定型文で共有',
  i18n_share_fixed_btn: '定型文を確認',
  i18n_copy_standard_text: '定型文をコピー',
  i18n_share_via_email: 'メールで共有',
  i18n_copy_via_email: '送付先を指定',
  i18n_all_adjustment_list: '全て',
  i18n_adjusting_list: '調整中',
  i18n_adjustment: '調整済',
  i18n_day_number: '１',
  i18n_3day_number: '３',
  i18n_week_number: '７',
  i18n_memo: 'メモ',
  i18n_memo_placeholder: 'メモを入力',
  i18n_view_hide: '非表示',
  i18n_register_partner: 'お相手を登録してください',
  i18n_add_partner: '調整メール送信',
  i18n_voting: '調整中',
  i18n_voted: '調整状況み',
  i18n_calendar_unlink_other_google: 'Googleカレンダー連携解除',
  i18n_calendar_unlink_other_microsoft: 'Microsoftカレンダー連携解除',
  i18n_invite_member_success: '招待が完了しました',
  i18n_invite_member_error: '招待が失敗しました',
  i18n_start_date_invalid: '開始日付を終了日付前に選択してください。',
  i18n_login_btn: 'ログインする',
  i18n_google_login: 'Googleでログイン',
  i18n_microsoft_login: 'Microsoftでログイン',
  i18n_password_rule: '8文字以上の半角英数字で入力してください。',
  i18n_password_register_btn: '登録する',
  i18n_password_must_different:
    '新しいパスワードは現在のパスワードと異なるものを入力してください。',
  i18n_forgot_password_link: 'パスワードを忘れた場合はこちら',
  i18n_reset_password_title: '新パスワード登録',
  i18n_reset_password_label: '新パスワード登録',
  i18n_reset_password_confirm_label: '新パスワード登録(確認用)',
  i18n_reset_password_error: 'パスワードが一致しません。',
  i18n_reset_password_success: 'パスワードを変更しました。',
  i18n_scroll_note: '※最後までスクロールする必要があります。',
  i18n_choose_calendar: 'カレンダーから選択',
  i18n_auto_extract_candidate_tooltip: '候補日時を自動抽出します。',
  i18n_individual_title: '個人',
  i18n_my_calendar: 'マイカレンダー',
  i18n_other_calendar: '他のカレンダー',
  i18n_add_calendar: 'カレンダーを追加',
  i18n_email_address_is_required: 'メールアドレスは必須です。',
  i18n_calendar_creation_title_filed: 'イベント名',
  i18n_end_date_invalid: '終了日付を開始日付後に選択してください。',
  i18n_recommend_block: '候補日時がありません。',
  i18n_email_existed: 'メールが重複されています。',
  i18n_add_contact_success: 'メール送付先を更新しました',
  i18n_datepicker_not_setting: '未設定',
  i18n_template_mail_modal_title: '定型文の作成',
  i18n_menu_title: 'メニュー',
  i18n_profile_title: 'プロフィール',
  i18n_mail_template_title: '定型文の作成',
  i18n_contact_management_title: 'メール送付先管理',
  i18n_base_notation_title: 'プロフィール',
  i18n_document_title: 'ご利用ガイド',
  i18n_appointment_success_title: '送信完了',
  i18n_link_company_other: '他社カレンダー連携',
  i18n_change_password_title: 'パスワードを変更',
  i18n_schedule_setting_title: '自動日程調整オプション',
  i18_delete_event_title: 'このイベントを削除しますか？',
  i18n_label_event_created_by_me: '自分',
  i18n_label_event_created_by_other: '様',
  i18n_label_user_voted: '回答済',
  i18n_label_user_not_voted: '未回答',
  i18n_label_event_created: '出欠回答',
  i18n_copy_url_btn: 'URLで共有',
  i18n_btn_cancel_collaboration: '連携解除',
  i18n_cancel_collaboration_confirm: '本当にカレンダー連携を解除しますか',
  i18n_appointment_send_to_email: '日程の受信メールアドレス',
  i18n_btn_registration: '新規登録',
  i18n_collaboration_google_title: 'Googleカレンダーと連携',
  i18n_collaboration_microsoft_title: 'Outlookカレンダーと連携',
};
