import React from 'react';

function Pen() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="23"
      height="23"
      viewBox="0 0 23 23"
    >
      <image
        id="鉛筆アイコン8"
        width="23"
        height="23"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAYAAADgKtSgAAAABHNCSVQICAgIfAhkiAAAAbxJREFUSEullDlLxUAUhU2vFio8FEv/g5XWWrzGDbFSQXH3IViouK+t+4IgIuIKgoX/wkKs7QQFGwutBOM54Q5cw2SyvMBhJrl3vpzcuRnP9/2SIq4mrJ2EGqAXaBfaMTyvCHgfIEcWYyd41svnWeEDWLsv4FWMx1A9dCnPTjF2Z4Frx3eAtCj3zZg/yH0hLXxY1fQL81KIX3GoXpDH/B76TAMfkg0jp0ccPmKshUZUjPGgS5LC+5W7GczXxGklxmeoGhqEDqAO6Ar6SALXjqewaEOVgNMq6Amqgdg9NMIrdkPpZk+SteMQP7h9h3IS2MI47nKuS7GA5EUbUZ41YmTnVIgZbnxkzblB27JwFuOKA9yK2K3ENzEWTK7NuQbHlaIdoGsb2OZcl2IOCctZHNuca8fTSFh3gDsRu4hyHIbrs2IewSUHuA2xG4nzBByNymXNdbsRSnjUpR1zw8ccuUG3mAM9Dmz+PPL+dYXLuYF7DhepwaZbDJy/75vlBZnAYTiPz+8QvAv353Fd4SrLL4IsSZ1yzq/hn3cmC3m+BL90mktvaNS6RJtnW0z4KwLlUFko4Qf37OOJNG517h/ITJ55dV3EsgAAAABJRU5ErkJggg=="
      />
    </svg>
  );
}

export default Pen;
