import { YYYYMMDD, YYYYMMDDTHHmm } from '@/constant';
import moment from 'moment';
import { splitRange, isOverlap } from './function';
import { formatMessage } from 'umi';

export const autoGenerateRangeDateToBlock = ({
  setting,
  calendarEvents,
  bookedEvents,
  startDate,
  endDate,
  blockNumber,
}) => {
  let results = {};

  let curStartDate = moment(startDate);
  const curEndDate = moment(endDate);

  if (!curStartDate || !curEndDate) {
    return [];
  }

  const diff = curEndDate.diff(curStartDate, 'days');
  for (let i = 0; i <= diff; i++) {
    const date = moment(
      moment(curStartDate)
        .add(i, 'd')
        .format(YYYYMMDD),
    );
    const {
      filtedPastTimeBlocks,
      filtedBookedEventBlocks,
    } = autoGenerateDateToBlock({
      setting, // global setting, is team and ? group time and of team,  is team OR ? group time or of team,
      date,
      calendarEvents, // lich ban cua user checkbox
      bookedEvents,
      blockNumber,
    });

    if (filtedBookedEventBlocks && filtedBookedEventBlocks.length > 0) {
      filtedBookedEventBlocks.forEach(block => {
        const found = filtedPastTimeBlocks.find(e => {
          return block.srcId === e.srcId;
        });

        // valid block
        if (found) {
          found.title = formatMessage({ id: 'i18n_votes' });
          found.checked = true;
          found.backgroundColor = 'transparent';
          found.borderColor = 'none';
          found.textColor = '#333333';
        }
      });
    }

    results[date.format(YYYYMMDD)] = filtedPastTimeBlocks || [];
  }

  return results;
};

/**
 *
 * @param {*} payload
 * @returns
 */
export const autoGenerateDateToBlock = payload => {
  const {
    setting, // global setting, is team and ? group time and of team,  is team OR ? group time or of team,
    date,
    calendarEvents, // lich ban cua user checkbox
    bookedEvents,
    blockNumber,
  } = payload;

  if (!date || !setting || !blockNumber) {
    return [];
  }

  console.log(11, 'date', date.format(YYYYMMDD));

  // if date is off, no generate
  const dayOfWeek = moment(date).isoWeekday();
  const settingOfDayOfWeek = setting.find(item => {
    return item.day_of_week === dayOfWeek;
  });

  if (!settingOfDayOfWeek || !settingOfDayOfWeek.status) {
    return [];
  }

  // default_start_time
  // default_end_time
  let startTime = moment(date).set({ hour: 0, minute: 0, second: 0 });
  let endTime = moment(date).set({ hour: 23, minute: 59, second: 59 });

  const SPACER = 0;
  const allBlocks = splitRange(startTime, endTime, blockNumber, SPACER);

  if (!allBlocks || allBlocks.length <= 0) {
    return [];
  }

  console.log(22, 'allBlocks', allBlocks);

  // filter past time
  const filtedPastTimeBlocks = allBlocks.filter(block => {
    if (moment(block.end_time).unix() <= moment().unix()) {
      return false;
    }

    return true;
  });

  if (!filtedPastTimeBlocks || filtedPastTimeBlocks.length <= 0) {
    return [];
  }

  console.log(33, 'filtedPastTimeBlocks', filtedPastTimeBlocks);

  // filter setting
  const filtedSettingBlocks = filtedPastTimeBlocks.filter(block => {
    const startTime = moment(
      `${date.format(YYYYMMDD)} ${settingOfDayOfWeek.start_time}`,
    );
    const endTime = moment(
      `${date.format(YYYYMMDD)} ${settingOfDayOfWeek.end_time}`,
    );

    if (
      moment(block.start_time).isSameOrAfter(startTime) &&
      moment(block.end_time).isSameOrBefore(endTime)
    ) {
      return true;
    }

    return false;
  });

  if (!filtedSettingBlocks || filtedSettingBlocks.length <= 0) {
    return [];
  }

  console.log(44, 'filtedSettingBlocks', filtedSettingBlocks);

  // fillter calandar event
  const filtedCalendarBlocks = filtedSettingBlocks.filter(block => {
    const startTime = moment(block.start_time);
    const endTime = moment(block.end_time);

    if (!calendarEvents || calendarEvents.length <= 0) {
      return true;
    }

    for (const calendarEvent of calendarEvents) {
      const calendarStartTime = moment(calendarEvent.start_time);
      const calendarEndTime = moment(
        moment(calendarEvent.start_time)
          .add(calendarEvent.block_number, 'm')
          .format(YYYYMMDDTHHmm),
      );

      if (isOverlap(startTime, endTime, calendarStartTime, calendarEndTime)) {
        return false;
      }
    }

    return true;
  });

  if (!filtedCalendarBlocks || filtedCalendarBlocks.length <= 0) {
    return [];
  }

  console.log(55, 'filtedCalendarBlocks', filtedCalendarBlocks);

  // filter events
  const filtedBookedEventBlocks = filtedCalendarBlocks.filter(block => {
    if (!bookedEvents || bookedEvents.length <= 0) {
      return true;
    }

    let startTime = moment(block.start_time);
    let endTime = moment(block.end_time);

    // for (const bookedEvent of bookedEvents) {
    //   const bookedStartTime = moment(bookedEvent.start_time);
    //   const bookedEndTime = moment(bookedEvent.end_time);
    //   console.log(
    //     `${startTime.format(YYYYMMDDTHHmm)} - ${endTime.format(
    //       YYYYMMDDTHHmm,
    //     )} - ${bookedStartTime.format(YYYYMMDDTHHmm)} - ${bookedEndTime.format(
    //       YYYYMMDDTHHmm,
    //     )}`,
    //   );
    //
    //   if (isOverlap(startTime, endTime, bookedStartTime, bookedEndTime)) {
    //     return false;
    //   }
    // }

    return true;
  });

  console.log(66, 'filtedBookedEventBlocks', filtedBookedEventBlocks);

  return { filtedBookedEventBlocks, filtedPastTimeBlocks };
};
