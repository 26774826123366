export const defaultReceptionStart = [
  { id: 1, value: 0 },
  { id: 2, value: 60 },
  { id: 3, value: 180 },
  { id: 4, value: 360 },
  { id: 5, value: 720 },
  { id: 6, value: 1440 },
  { id: 7, value: undefined },
];
export const defaultReceptionEnd = [
  { id: 1, value: 30 },
  { id: 2, value: 60 },
  { id: 3, value: 180 },
  { id: 4, value: 360 },
  { id: 5, value: 720 },
  { id: 6, value: 1440 },
  { id: 7, value: undefined },
];
export const defaultRelaxTime = [
  { id: 1, value: 0 },
  { id: 2, value: 15 },
  { id: 3, value: 30 },
  { id: 4, value: 45 },
  { id: 5, value: 60 },
  { id: 6, value: undefined },
];
export const defaultPeriod = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: undefined },
];
export const defaultReservation = [
  { id: 1, value: 50 },
  { id: 2, value: 100 },
  { id: 3, value: 150 },
  { id: 4, value: 200 },
  { id: 5, value: 250 },
  { id: 6, value: undefined },
];
export const defaultMinVote = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: undefined },
];
