import React from 'react';

export default function Trash() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="13"
      height="15"
      viewBox="0 0 13 15"
    >
      <image
        id="ゴミ箱のアイコン素材"
        width="13"
        height="15"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAPCAYAAAA/I0V3AAAABHNCSVQICAgIfAhkiAAAAWdJREFUKFOVkj0vBFEUhufOJhKRSNhGQrclKpEIxSYE85nxUUhEopFoyMY2aHw0oiGyGok/oCKhsY1KpeQnUCkVKMyM58yeSUQz3OTJ+945c84998yYIAgsWcaYTJMkmUM2oVO2aZo2bduuSxzfejdPynaWNU3gFn2BV2iDAWiCkxeWpDIPbK16jY4SbEc/tdA5hVbwFZ6/oanxfb915j+WJD1oK1JZKn1Bl9aQi77Dhz6TfY8kyRDGaaFb25STEyhJK798yrs3xnVdi+k8E+z7S4cUr2YnsR5hEJZgAUL1y+ik+kXUg6E86YmNjFZ6rsOR+n10R718u0NO6jeO40h79/Q6psEDdFv9Kbqm/hjdiOO4YjzPk0HIx5sqSDohXuOkXhOGofwelzBbkNQgvk5XZRNFkfxvFyADkDvVQKqK34W9n/cjqSMbOe2dwSrBLXChqn4GHVE/jw5zp1I+PQleQdG646SJbwvQeRI1bfyEAAAAAElFTkSuQmCC"
      />
    </svg>
  );
}
