import React from 'react';

export default function Checked() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="23"
      height="19"
      viewBox="0 0 23 19"
    >
      <image
        id="チェックボックスのフリーアイコン_1_"
        dataName="チェックボックスのフリーアイコン (1)"
        width="23"
        height="19"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAATCAYAAAB7u5a2AAAABHNCSVQICAgIfAhkiAAAAXtJREFUOE+tlD8oRVEcx72kiJGBUs8kfyKTKKSUKC/ZGJSyKJKFhYmiLArTo2wmCeUVZZBiNGHDapGyGHR9fnVv/ZzO791b7/7q2/n9+f6+59xzzzmZIAjKUrZ99DpAPpOieBOCp6AzXOxBWuJDCF6AylB4m3E5DfExhM7V1h7jT0lcqngOjTMlfIffF8WliA8icqOEP/HrwY8lXkPhO8HpaYHz5PC6iB91Tq98k8I0aAeyCsuqKbyBWkWYx5cj+M8i8RWyW2HlhVFWZtkDhW5VvMUf8JFFfJeCzKytQDDqadght6jyv/gN4MMSz1OY9RSPyM2o/Aj+pcNbIN6zPjHalhMIEx7SBrk1UAW+QIXiyA9ts4Qlr3/oK3HWQ54k1w/mnFoPsey/aVq8EZZMUF6sIaxdMQ7H8dxLJG/EdVwT9VbwHMfz3dBVmtaLNJpHz+2xrr88nePGBL3k7+NW7f5Qly+XqdlJvhNnkwjHicsjJK9cnRJbwj9MKv4HwEJr1OXBQJcAAAAASUVORK5CYII="
      />
    </svg>
  );
}
