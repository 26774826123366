import React, { useEffect, useState } from 'react';
import styles from './styles.less';
import { useIntl, history } from 'umi';
import { connect } from 'dva';
import { Row, Col } from 'antd';
import HomeHeader from '@/components/HomeHeader';
import config from '@/config';
import HeaderMobile from '@/components/Mobile/Header';
import iconBack from '@/assets/images/i-back-white.png';
import { ROUTER } from '@/constant';

function TermOfUser(props) {
  const { dispatch, masterStore } = props;
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <div className={styles.termOfUser}>
      <HeaderMobile
        title={formatMessage({ id: 'i18n_term_of_user' })}
        isShowLeft={true}
        itemLeft={{
          event: 'back',
          url: ROUTER.menu,
          icon: iconBack,
          bgColor: 'bgPrimaryBlue',
          textColor: 'textLightGray',
        }}
      />
      <div className={styles.content}>
        <Row>
          <p className={styles.title}>第1条（利用規約への同意）</p>
          <p>
            1．株式会社ビジョン（以下「当社」という。）は、当社の運営する「タイムマッチ」（以下「本システム」といいます。）について、以下に定める利用規約（以下「本規約」といいます。）に従いシステムの提供を行うものとします。
          </p>
          <p>
            2．本システムを利用する法人（各種団体等を含むものとする。以下同じ。）および個人（以下「契約者」といいます。）は、本規約にすべて同意するものとします。
          </p>

          <p className={styles.title}>第2条 （タイムマッチの提供システム）</p>
          <p>
            本システムは、当社のインターネットサイト（以下「当社サイト」といいます。）上に、契約者のスケジュールを考慮した面談等の実施可能な日程候補を掲載し、第三者が当該日程候補に面談等を予約することができるようにすることで、第三者との間でスケジュールの調整を可能にするシステムをいいます。
          </p>

          <p className={styles.title}>第3条 （本システムの利用上の注意）</p>
          <p>
            1.当社は、本規約に基づき本システム利用に係るシステムを提供するものとし、契約者は、本規約に定める義務を誠実に履行するものとします。
          </p>
          <p>
            2.本システムの内容および機能に関する詳細については、本システムが提供する画面（以下「サポート画面」といいます。）上で、別途表示されるものとします。
          </p>
          <p>
            3.当社は、本システムの提供の全部または一部を第三者に委託できるものとし、契約者は、かかる委託を承諾するものとします。
          </p>

          <p className={styles.title}>第４条 （個人情報の取扱い）</p>
          <p>
            当社は、本システムにおいて、契約者の個人情報を取り扱う場合は、当社が別途定めるプライバシーポリシーに基づき、適切に、契約者の個人情報を取り扱うものとします。
          </p>

          <p className={styles.title}>第５条 （利用環境の整備等）</p>
          <p>
            1.契約者は、本規約に基づいて本システムを利用するために必要なハードウェアおよびネットワーク並びに当社が推奨する環境等の設備を、自己の責任と負担により調達しなければなりません。
          </p>
          <p>
            2.契約者は、本システムを通じて契約者とのスケジュールの調整を行う者（以下「予約者」といいます。）との間で、当該スケジュール調整の結果の通りに対応するよう努めるものとします。
          </p>
          <p>
            3.契約者は、前項の規定に基づき予約者に開示される候補日程が、予約時において、契約者の実際のスケジュールと同一であることを保証するものとします。
          </p>

          <p className={styles.title}>第６条（契約者の基本的遵守事項等）</p>
          <p>
            1.契約者は、本システム上の予約者に対し、他の方法により予約した者に比べ不利な扱いをする等して、当社および本システムの信用を損なう行為を行わないものとします。
          </p>
          <p>
            2.契約者は、本システムを通じたスケジュールの調整結果等につき、予約者その他第三者からの苦情（契約者に関する苦情を含みますが、これに限られません。）等を受けた場合には、当該苦情等につき誠実に対応・回答するものとします。なお、当社を通じて予約者の苦情等の報告を受けた場合も同様とします。また、当該システム利用における予約者その他第三者とのトラブルに関して、当社は責任を負いません。
          </p>
          <p>
            3.契約者は、本システムを通じたスケジュールの調整等に関連して、予約者その他の第三者との間で紛争等が生じた場合、自己の責任と負担において、当該第三者に対し誠実かつ妥当な対応を行い、紛争等の解決を図るものとし、
            当社を予約者との紛争等から一切免責するものとします。なお、本項の規定は、当社が必要と認めた場合、当社が契約者に代わって第三者に対応することを妨げるものではありません。当社が契約者に代わって対応を行った場合、当社は対応に要した費用を当該契約者に請求することができるものとします。
          </p>
          <p>
            4.契約者は、万一、スケジュールのキャンセルの事由により、本システムを通じたスケジュールの調整結果等に対応できない場合には、自己の責任と負担において、直ちに予約者に対してスケジュールのキャンセル等を通知するものとします。
          </p>
          <p>
            5.当社は、予約者が、事前に何らの連絡なく、予め本システムを通じてスケジュールの調整をした日時に姿を現さなかった等の当該予約者との間で生じたいかなるトラブルにも関与せず、契約者に生じた損害の賠償等を行う義務を負わず、当該契約者が予約者に対し当該損害の賠償等を請求する場合は、自己の費用と負担においてこれを行うものとします。
          </p>

          <p className={styles.title}>第７条（禁止事項）</p>
          <p>
            1.契約者は、本システムの利用に関し、本システムの管理・運営および編集権限が当社にあることを了承した上で、次の行為をしてはならないものとします。なお、契約者の行為が以下の項目に該当するか否かは、当社が合理的に判断するものとし、契約者は、当社の判断に対し、何らの異議を申し立てないものとします。
          </p>
          <p>（1）当社に虚偽の事項を届け出る行為</p>
          <p>（2）法令の定めに違反する場合</p>
          <p>（3）犯罪に結びつく行為およびその可能性のある行為</p>
          <p>（4）公序良俗に反する行為</p>
          <p>（5）当社の判断に錯誤を与えるおそれのある行為</p>
          <p>
            （6）当社および当社の委託先（委託先の従業員等も含みます。）、他の契約者は第三者に
            対して、権利（知的財産権を含みます）の侵害、プライバシーの侵害、誹謗中傷その他の不利益を与える行為またはそのおそれのある行為
          </p>
          <p>（7）当社または第三者を差別または誹謗中傷する行為</p>
          <p>（8）当社または第三者の信用を損なう行為</p>
          <p>
            （9）当社のシステム業務（本システムを含みますがこれに限られません。）の運営・維持を妨げる行為
          </p>
          <p>（10）本システムに関し利用しうる情報を改竄する行為</p>
          <p>（11）有害なコンピュータープログラム等を送信または書き込む行為</p>
          <p>（12）本システムの仕様を当社の同業他社等第三者に漏洩する行為</p>
          <p>（13）実現不可能な内容を登録する行為</p>
          <p>（14）他人になりすまして本システムを利用する行為</p>
          <p>（15）当社の承認した以外の方法により、本システムを利用する行為</p>
          <p>（16）当社または第三者に対する迷惑行為</p>
          <p>（17）その他当社が不適切と判断した行為</p>
          <p>
            2.契約者は、以下の各号に該当する内容の予約を受け付けるために、本システムを利用してはならないものとします。なお、契約者の行為が以下の項目に該当するか否かは、当社が合理的に判断するものとし、契約者は、当社の判断に対し、何らの異議を申し立てないものとします。
          </p>
          <p>
            （1）
            事業内容、営業方法等が関係諸法規に違反すると判断されるシステムの予約
          </p>
          <p>（2） 悪質商法に関するシステムの予約</p>
          <p>
            （3）
            契約者および予約者の安全・安心の観点から、著しく不適合と判断されるシステムの予約
          </p>
          <p>
            （4）
            プライバシーの侵害、差別を肯定・助長する可能性が高いと判断されるシステムの予約
          </p>
          <p>（5） 宗教広告等の思想信条に関わるシステムの予約</p>
          <p>（6） ネットワークシステムによる販売方法に関わるシステムの予約</p>
          <p>（7） 性的な要素をシステムとした予約</p>
          <p>（8） 開運関連の要素をシステムとした予約</p>
          <p>（9） 科学的根拠の乏しいと判断されるシステムの予約</p>
          <p>
            （10）
            その他上記各号に関わると判断されるシステムの予約または当社が別途定めるシステムの予約
          </p>
          <p>
            3.契約者が前２項の規定に違反した場合、その他当社が本システムの運営上不適当と判断する行為を契約者が行った場合には、当社は、当該契約者に対して、何らの通知、催告または理由の開示なしに、本システムの利用停止、調整済みスケジュールの削除、損害賠償請求等、当該契約者の行為の防止に必要な措置(法的措置を含みます。)を採ることができるものとし、当該行為に起因して契約者に発生したいかなる損害についても、賠償責任を負いません。
          </p>

          <p className={styles.title}>第８条 （反社会的勢力の排除）</p>
          <p>
            1.契約者は、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団等、その他これらに準ずる者（以下これらを「暴力団員等」といいます。）に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
          </p>
          <p>
            （1）
            暴力団員等が経営を支配または実質的に関与していると認められる団体その他これらに準ずる者と関係を有すること
          </p>
          <p>
            （2）
            自己もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること
          </p>
          <p>
            （3）
            暴力団員等に対して資金等を提供し、または便宜を供与するなどの関与をしていると認められる関係を有すること
          </p>
          <p>
            2.契約者は、自らまたは第三者を利用して次の各号の一にでも該当する行為を行わないことを確約するものとします。
          </p>
          <p>（1） 暴力的な要求行為</p>
          <p>（2） 法的な責任を超えた不当な要求行為</p>
          <p>（3） 取引に関して、脅迫的な言動をし、または暴力を用いる行為</p>
          <p>
            （4）
            風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為
          </p>
          <p>（5） その他前各号に準ずる行為</p>

          <p className={styles.title}>第９条（ 契約者情報の入力等）</p>
          <p>
            1.契約者は、本システムに登録された、当該契約者に関する情報（氏名・名称、住所、連絡先等を含みますが、これらに限られません。）およびそれらに関連するその他一切の情報（以下「契約者情報」といいます。）に変更が生じた場合には、変更内容の登録を行わなければなりません。
          </p>
          <p>
            2.契約者は、本システムを利用して契約者情報を入稿する場合（当社が契約者の指示に従い入稿する場合も含みます。）、運用ルールに従って契約者自らが正確に入力するものとします。
          </p>
          <p>
            3.当社は、契約者情報が運用ルールに適う内容であるか否かを審査することができるものとします。運用ルールに反する契約者情報の存在が判明した場合等、当社が必要と判断した場合には、契約者は、当社が当該契約者情報の削除・変更を行う場合があることを承諾するものとします。また、当該契約者情報に関して当社より削除・変更の要請があった場合には、これに速やかに応じるものとします。
          </p>
          <p>
            4.当社が前項の規定に基づき、契約者情報の削除・変更を行った場合および契約者に削除・変更の要請を行った場合において、契約者に損害が生じたとしても、当社に故意または重過失が認められる場合を除き、賠償する責任を負いません。
          </p>
          <p>
            5.契約者は、本条に違反したことにより予約者その他の第三者との間で生じた一切の紛争および損害については、自らの責任と費用をもって負担するものとします。
          </p>

          <p className={styles.title}>第１０条（ 本システムの修正）</p>
          <p>
            当社は、必要に応じて、本システムの修正（アップデート）をするものとし、これに起因して契約者または第三者に損害が発生した場合であっても、当社は、当社に故意または重過失が認められる場合を除き、賠償責任を負わないものとします。
          </p>

          <p className={styles.title}>第１１条（ 本システムの変更等）</p>
          <p>
            1.当社は、以下の各号に掲げる場合、本システムの全部または一部を、契約者への予告なく停止することができ、これに起因して契約者または第三者に損害が発生した場合であっても、当社は、当社に故意または重過失が認められる場合を除き、賠償責任を負わないものとします。
          </p>
          <p>
            （1）
            定期的または緊急に、本システムの提供のためのシステムの保守または点検を行う場合（第三者提供システムの仕様変更に伴う場合を含みます。）
          </p>
          <p>
            （2）
            火災、停電、天災地変等の非常事態により、本システムの提供が困難または不能となった場合
          </p>
          <p>
            （3）
            戦争、内乱、暴動、騒擾、労働争議等により、本システムの提供が困難または不能となった場合
          </p>
          <p>
            （4）
            本システムの提供のためのシステムの不良および第三者からの不正アクセス、コンピューターウィルスの感染等により本システムの提供が困難または不能となった場合
          </p>
          <p>
            （5）
            法令等に基づく措置により、本システムの提供が困難または不能となった場合
          </p>
          <p>
            （6）
            第三者提供システムまたは当社が本システムを提供する上で利用する第三者のシステムの停止または終了（保守、仕様の変更、瑕疵の修補による停止を含みますが、これらに限りません。）により、本システムの提供が困難または不能となった場合
          </p>
          <p>（7） その他当社が止むを得ないと判断した場合</p>
          <p>
            ２
            当社は、本システムの全部または一部を、契約者への予告なく改訂、追加、変更または廃止することができ、これに起因して契約者または第三者に損害が発生した場合であっても、当社は、当社に故意または重過失が認められる場合を除き、賠償責任を負わないものとします。
          </p>

          <p className={styles.title}>第１２条 （権利の帰属）</p>
          <p>
            1.本システムにおける当社コンテンツに関する一切の知的財産権は、当社又は当社にライセンスを許諾している者に帰属するものとし、本システムの利用を以て、当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
          </p>
          <p>
            2.本システム上、当社の商標、ロゴ及びシステムマーク等（以下、総称して「商標等」といいます。）が表示される場合がありますが、当社は、契約者その他の第三者に対し、商標等を譲渡し、または本規約で明示する以外の使用を許諾するものではありません。
          </p>
          <p className={styles.title}>
            第１３条 （契約期間・解除・損害賠償・システム終了等）{' '}
          </p>
          <p>
            1.本契約の有効期間は、当社サイトに定めるとおりとします。なお、本契約の有効期間が１年以上の場合には、本契約期間満了日の1ヶ月前までに当社または契約者のいずれからも別段の意思表示が為されない場合には、同一条件で1年間延長されるものとし、以後も同様とします。契約更新日にあたる日付
            (31日等) がない月は、代わりにその月の翌月1日が契約更新日となります。
            料金は請求期間の初日に請求されます。
          </p>
          <p>
            2.当社および契約者は、理由の如何を問わず、本契約期間中いつでも、1ヶ月の予告期間をもって相手方に書面で通知することにより、本契約を解約することができます。なお、当社が契約者による解約方法を別途定めた場合には、契約者は当該方法により解約することができます。
          </p>
          <p>
            3.
            前項にかかわらず、契約者または当該契約者に関連する施設、個人、法人もしくは団体が次の各号に該当する場合、当社は、直ちに当該契約者による本システムの使用を停止し、契約者情報を削除し、または本契約を解除することができます。
          </p>
          <p>（1）本規約の規定に違反したとき</p>
          <p>（2）当社の信用を傷つけたとき</p>
          <p>
            （3）差押え、仮差押え、仮処分、租税滞納処分を受け、または破産、民事再生、特別清算、
            会社更生を自ら申し立てもしくは申し立てを受けたとき
          </p>
          <p>
            （4）死亡し、または後見開始、保佐開始もしくは補助開始の審判を受けたとき
          </p>
          <p>
            （5）手形・小切手の不渡処分を受け、またはその他支払い不能となったとき
          </p>
          <p>（6）事業の全部または重要な部分を他に譲渡したとき</p>
          <p>（7）合併、分割等により経営環境に大きな変化が生じたとき</p>
          <p>（8）財産状況に重大な不安が生じたとき</p>
          <p>（9）営業を廃止したとき、または清算にはいったとき</p>
          <p>
            （10）当社に不利益をもたらしたとき、または不利益をもたらす恐れがある行為をしたとき
          </p>
          <p>
            （11）当社および当社の委託先（委託先の従業員等を含みます。）に対する暴言・暴力行為が行われたとき
          </p>
          <p>
            （12）当社との間でトラブルや紛争が発生して、当社との間の信頼関係が破壊されたまたはその恐れがあると、当社が合理的に判断したとき
          </p>
          <p>（13）当社の参画基準に抵触すると当社が判断したとき</p>
          <p>
            （14）予約者等からの苦情、予約者等との間のトラブル等から、当該契約者による本システムの利用が、本システムの信用等に影響を及ぼす可能性があると当社が合理的に判断したとき
          </p>
          <p>
            （15）その他本規約に定める事項を遂行できる見込みのなくなったとき
          </p>
          <p>
            4.契約者は、当社が前項14号の規定に基づいて本システムの信用等に影響を及ぼす可能性があると判断した事実、資料等を、当社の求めに応じ、当社に対して開示するものとし、当該開示に対して、何らの異議を申し立てないものとします。
          </p>
          <p>
            5.本契約終了日において、既に契約者と予約者の間で本契約終了日の翌日以降のスケジュールの調整が行われている場合、契約者は、自己の責任において、当該スケジュールに対応するものとします。
          </p>
          <p>
            6.本契約の終了原因を問わず、契約者は、本契約終了日の翌日以降、本アカウントを使用することができず、本契約終了日までに本システム上に登録された一切の情報を削除しなければなりません。なお、契約者が自ら当該情報を削除しない場合、当社はいつでも当該情報を削除することができます。
          </p>
          <p>
            7.本規約に定める他、当社は、契約者が、法令並びに本規約の規定に違反した場合、当該違反を理由に本契約を解除できるものとし、当該解除と併せて当該違反により当社に生じた損害（遅延損害金、弁護士費用を含みます。がこれに限られません。）の賠償を契約者に対して請求することができます。
          </p>
          <p>
            8.当社が本システムを終了する際、終了前の1か月前に契約者に通知することで、終了することができるものとします。
          </p>
          <p className={styles.title}>第１４条 （当社の免責）</p>
          <p>
            1．
            本システムの欠陥に関して契約者に損害が生じたときは、当社に故意または重過失が認められる場合を除き、当社は損害賠償責任を負わないものとします。
          </p>
          <p>
            2
            前項の規定により、当社が損害賠償責任を負う場合であっても、当社の行為に直接起因する損害についてのみ、当社の行為のあった月に当社が受領した本件システムの利用料金の１か月分に相当する額を上限としてその損害を賠償するものとし、それ以外の損害については一切の責任を負わないものとします。
          </p>
          <p>
            3．契約者が本規約に違反することにより当社または第三者に損害を与えた場合、契約者は当社または当該第三者に対し、生じた損害の一切を賠償しなければならないものとします。当社は、契約者に対する送客および予約等について、何らの保証も行わないものとします。
          </p>
          <p>
            4．本システムに関して契約者と第三者との間に紛争が生じた場合、契約者は自己の責任と費用でこれを解決するものとします。またこれにより当社に損害を与えた場合は、契約者は当社が被った損害（弁護士費用を含みます。）を補償するものとします。
          </p>
          <p>
            5．本システムを媒介とした契約者相互間または契約者と第三者間の一切の取引および紛争等に関して、当社は一切関知せず、いかなる責任も負わないものとします。
          </p>
          <p className={styles.title}>第１５条 （秘密保持）</p>
          <p>
            1.契約者は、当社の事前の書面による承諾がある場合を除き、本システムに関連して当社が開示した一切の情報を秘密に取り扱うものとし、第三者に開示、漏洩してはならず、本システムの利用以外の目的で使用してはならないものとします。
          </p>
          <p>
            2.契約者は、当社から求められた場合はいつでも、当社の指示に従い、遅滞なく、前項の情報及び当該情報を記載又は記録した書面その他の記録媒体物並びにその全ての複製物等を返却又は廃棄しなければなりません。
          </p>
          <p className={styles.title}>
            第１６条 （ログインID、パスワードおよびユーザーの管理）
          </p>
          <p>
            1．契約者は、契約者がユーザーページにアクセスする際に必要となるログインID（ログイン時に必要となるメールアドレスおよび認証IDを含む。以下「ログインID」という。）およびパスワードを、自己の責任において厳重に管理し、これらを用いてなされた一切の行為についてその責任を負うものとします。
          </p>
          <p>
            2．契約者は、契約者配下ユーザーのログインIDおよびパスワードについても、契約者の責任において厳重に管理し、また契約者配下のユーザーをして厳重に管理させるものとし、これらを用いてなされた一切の行為についてその責任を負うものとします。
          </p>
          <p>
            3．契約者は、契約者および契約者配下のユーザーのログインIDおよびパスワードを、第三者に貸与、譲渡、売買、質入、開示してはならないものとし、また本システムを第三者に利用させないものとします。
          </p>
          <p>
            4．契約者は、契約者および契約者配下ユーザーのログインIDおよびパスワードが第三者によって不正に使用されたことが判明した場合、もしくはその可能性がある場合には、直ちにその旨を当社に連絡するとともに、当社の指示に従うものとします。
          </p>
          <p>
            5．契約者によるログインIDおよびパスワードの管理不十分（パスワードの定期的な変更を怠った場合も含む。）、使用上の過誤、第三者の使用等による損害の責任については契約者自身が負うものとし、当社および業務提携企業は一切の責任を負わないものとします。また、ログインIDおよびパスワードが不正に使用されたことにより当社または業務提携企業に損害が生じた場合、契約者は、当社または業務提携企業に対し、その一切の損害を賠償するものとします。
          </p>
          <p>
            6．契約者は、契約者配下のユーザーに本規約の内容を遵守させるものとします。契約者配下のユーザーの本規約違反は、契約者の本規約違反とみなし、契約者およびユーザーは連帯して責任を負うものとします。
          </p>
          <p className={styles.title}>
            第１７条（連携外部システムからの情報の取得）
          </p>
          <p>
            1．本システムは、契約者または契約者配下のユーザーがユーザーページ機能から連携外部システムとの連携を許諾することにより、連携外部システムとの情報の連携を行います。情報の連携とは、本システムの機能を構成するために必要な情報の取得・登録・変更・通知をいい、契約者が本システム上で連携外部システムで使用可能なID等の情報を入力した場合には、当社は、第三者提供システムから契約者の情報を取得し、当該第三者提供システムに係る契約者の情報を更新、編集、追加、削除等することができるものとし、契約者は予めこれを承諾するものとします。
          </p>
          <p>
            2．当社は、本システムと連携外部システムとの連携の正確性・完全性・継続性を保持するため最善を尽くしますが、連携外部システムの仕様変更・システム不具合または本システムのシステム不具合等により、連携の正確性・完全性・継続性が保持されない可能性があることを契約者は予め承諾し、本システムを利用するものとします。また、連携が不完全だった場合に生じた契約者の被った一切の損害について、当社は免責されるものとします。
          </p>
          <p>
            3．契約者が連携外部システムを利用するにあたっては、契約者は連携外部システムを提供する法人または団体との間で別途利用契約を締結するものし、契約者の連携外部システムに関する権利義務関係について、当社は一切の責任を負わないものとします。また、契約者が連携外部システムを利用するにあたって発生した紛争等は、契約者自らの責任と費用によって解決するものとし、当社は一切関知しないものとします。
          </p>
          <p className={styles.title}>第１８条 （利用料金および支払方法）</p>
          <p>
            1．本システムの利用料金は、本サイト上に掲載しているとおりとします。また、契約者に対して適用する本システムの利用料金は、契約者が本システムの利用申し込みを行った時点において本サイト上に記載していた料金とし、契約者は定められた利用料金を当社に支払うものとします。
          </p>
          <p>
            2．本システムに関する利用料金の支払方法および支払サイトは本サイト上に掲載しているとおりとし、契約者は当社に対し、定められた支払方法および支払サイトにて利用料金を支払うものとします。
          </p>
          <p>
            3．当社は、相当の猶予期間をもって契約者に告知することにより、契約者に適用する利用料金・支払方法・支払サイトを変更できるものとします。
          </p>
          <p>
            4．契約者は、利用料金の支払いに関する全ての手数料を負担するものとします。
          </p>
          <p>
            5．当社は、いかなる場合にもすでに受領した利用料金の返金には応じないものとします。
          </p>
          <p>
            6．契約者が利用料金の支払いを遅延した場合、適用法令の定める利率による発生利息および遅延賠償金を当社に支払うものとします。
          </p>
          <p>
            7．当社は、原則として、利用料に係る領収書を発行しないものとします。
          </p>
          <p className={styles.title}>第１９条（本規約の変更）</p>
          <p>
            1.当社は、次の場合には、本規約の内容の変更又は追加（以下「本規約の変更等」をいいます。）することができるものとします。
          </p>
          <p>（1）本規約の変更等が、契約者の一般の利益に適合する場合</p>
          <p>
            （2）本規約の変更等が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものである場合
          </p>
          <p>
            2.変更後の本規約は、当社が別途定める場合を除いて、効力発生日の７日前までに、本システムが提供される端末の画面上で表示することにより、契約者に通知するものとします。
          </p>
          <p>
            3.契約者は、変更後の本規約に同意できない場合、本ソフトウェアおよび本システムの利用を終了するものとし、変更後の本規約の効力が生じた後に、契約者が、本ソフトウェアおよび本システムを継続して利用した場合には、変更後の本規約の内容に同意したものとみなされます。
          </p>
          <p className={styles.title}>第２０条（本規約の有効性）</p>
          <p>
            1.本規約の一部の規定が法令に基づいて無効とされた場合であっても、本規約中その他の規定の効力に影響を及ぼしません。
          </p>
          <p>
            2.本規約の全部または一部の規定が、ある契約者との関係で無効とされ、または取り消された場合でも、本規約はその他の契約者との関係では効力を有するものとします。
          </p>
          <p className={styles.title}>第２１条（権利義務の譲渡禁止）</p>
          <p>
            1.契約者は、当社の書面による事前の承諾なく、本契約に基づく権利または義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分（以下「譲渡等」といいます。）をすることはできないものとします。本項に反して本契約に基づく権利または義務を第三者に譲渡等した場合、当社は、催告をせず、本契約を直ちに解除できるものとし、かつ、契約者に対して、違約金として金30万円（実際に当社に発生した損害額が当該金額を上回る場合には、当該損害額）の支払いを請求することができるものとします。
          </p>
          <p>
            2.当社が本システムに係る事業を第三者に譲渡し、又は当社が消滅会社若しくは分割会社となる合併若しくは会社分割等により本システムに係る事業を包括承継させたときは、当社は、当該事業譲渡等に伴い、本システムに関する本規約上の地位、権利及び義務並びに契約者情報その他の契約者に関する情報を当該事業譲渡等の譲受人又は承継人に譲渡することができるものとし、契約者は、予めこれに同意するものとします。
          </p>
          <p className={styles.title}>第２２条（存続条項）</p>
          <p>
            損害賠償請求および免責に関する規定、秘密保持に関する規定、次条その他契約の終了後も解釈上存続すべき規定は、本利用契約の終了後も有効に存続するものとします。
          </p>
          <p className={styles.title}>第２３条（準拠法および専属的合意管轄）</p>
          <p>
            本規約の準拠法は日本法とし、本規約に関する紛争の第一審の専属的合意管轄裁判所は、訴額に応じ、東京地方裁判所または東京簡易裁判所とします。
          </p>
          <p className={styles.title}>第２４条（協議解決）</p>
          <p>
            1.当社及び契約者は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
          </p>
          <p>
            2.当社及び契約者は、前項の協議を行うに際して、相手方が要求する場合、当該協議を行う旨の書面又は電磁的記録による合意をしなければならないものとします。
          </p>
          <p>以上</p>
        </Row>
      </div>
    </div>
  );
}

export default connect(({ MASTER }) => ({
  masterStore: MASTER,
}))(TermOfUser);
