const config = {
  API_DOMAIN: 'https://smoothly-be.miichisoft.net',
  WEB_DOMAIN: 'https://smoothly-fe.miichisoft.net',
  SHORT_DOMAIN: 'smoothly-be.miichisoft.net',
  GOOGLE_CLIENT_KEY:
    '853229000719-msltqgr9s6hboq7qbk84irqs9sop5csu.apps.googleusercontent.com',
  MICROSOFT_CLIENT_KEY: '130a44cb-4b27-4fd1-ad1a-c2bb04d35711',
  STRIPE_PUBLIC_KEY:
    'pk_test_51J5MXZHnPkpRlEbhLzXmlZXYQKsZkIdXNaFKJq7wOxeKZi6P15zr62c41BQHs9CUhSjim1bzOGS34GfMJqhQkwGr00TZDDCQv0',
  ADMIN_HOSTNAME: 'https://smoothly-be.miichisoft.net/admin',
  SOCKET_DOMAIN: 'https://websocket.smoothly.miichisoft.net',
  ENV: 'local',
  URL_COMPANY: 'https://smoothly-fe.miichisoft.net/company',
};

export default config;
