import React, { useEffect, useState } from 'react';
import styles from './styles.less';
import { useIntl, history } from 'umi';
import { connect } from 'dva';
import { Row, Col } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import HeaderMobile from '@/components/Mobile/Header';
import iconBack from '@/assets/images/i-back-white.png';
import { ROUTER } from '@/constant';

function PrivacyPolicy(props) {
  const { dispatch, masterStore } = props;
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <div className={styles.privacyPolicy}>
      <HeaderMobile
        title={formatMessage({ id: 'i18n_privacy_policy' })}
        isShowLeft={true}
        itemLeft={{
          event: 'back',
          url: ROUTER.menu,
          icon: iconBack,
          bgColor: 'bgPrimaryBlue',
          textColor: 'textLightGray',
        }}
      />
      <div className={styles.content}>
        <Row>
          <Col xs={{ span: 24, offset: 0 }} sm={{ span: 20, offset: 2 }}>
            <p className={styles.title}>第1条　個人情報保護方針</p>
            <p>
              株式会社ビジョン（以下「当社」という）は以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、
              <br />{' '}
              全従業員に個人情報保護の重要性の認識と取組みを徹底させることにより、個人情報の保護を推進します。
            </p>

            <p className={styles.title}>第2条　個人情報管理</p>
            <p>
              当社は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。
            </p>
            <p className={styles.title}>第3条　利用目的</p>
            <p>
              1.属性情報・端末情報・位置情報・行動履歴等（以下「行動履歴等」といいます）のうちCookie等について
            </p>
            <p>
              当社は、ユーザーのプライバシーの保護、利便性の向上、広告の配信および統計データの取得のため、Cookieを使用します。また、CookieやJavaScript等の技術を利用して、ご提供いただいた情報のうち、年齢や性別、職業、居住地域など個人が特定できない属性情報（組み合わせることによっても個人が特定できないものに限られます）や端末情報、本サービスサイト内におけるユーザーの行動履歴（アクセスしたURL、コンテンツ、参照順等）およびスマートフォン等利用時の、ユーザーの承諾・申込みに基づく位置情報を取得することがあります。ただし、Cookie及び行動履歴等には個人情報は一切含まれません。
            </p>
            <p>2.行動履歴等のうち以下の場合のアクセスログ等について</p>
            <p>
              当社はユーザーがログインして本サービスを利用した場合には、個人を特定したうえで、当該ログイン以前からの行動履歴等を用いて、広告・コンテンツ等の配信・表示および本サービスの提供をする場合があります。こちらの広告等の配信停止については、下記の個人情報管理の問い合わせ先にご連絡ください｡
              本サービスでは、お客様からのご利用申込みやお問い合わせをいただいた際に、お名前、e-mailアドレス、電話番号等の個人情報をご登録いただく場合がありますが、お客様の個人情報は、本サービスのご提供、当社の業務やサービスのご案内、お問い合わせ内容に対する回答などに利用します。
            </p>
            <p>
              3.個人情報の利用目的は以下の通りです。利用目的を超えて利用することはありません。
            </p>
            <p>A.ユーザーの個人認証及びユーザー向け本サービスの提供</p>
            <p>
              B.本サービスの利用に伴う連絡・メールマガジン・DM・各種お知らせ等の配信・送付
            </p>
            <p>
              C.ユーザーの承諾・申込みに基づく、本サービス利用企業等への個人情報の提供
            </p>
            <p>
              D.属性情報・端末情報・位置情報・行動履歴等に基づく広告・コンテンツ等の配信・表示、本サービスの提供
            </p>
            <p>
              E.本サービスの改善・新規サービスの開発およびマーケティングF.キャンペーン・アンケート・モニター・取材等の実施
            </p>
            <p>G.空メール送信者に対するURL情報の配信</p>
            <p>
              H.本サービスに関するご意見、お問い合わせ、クチコミ投稿内容の確認・回答
            </p>
            <p>
              I.利用規約等で禁じている、商用・転用目的での各種申込行為、各種多重申込、権利譲渡、虚偽情報登録などの調査と、それに基づく当該申込内容の詳細確認
            </p>
            <p className={styles.title}>
              第4条　個人情報の第三者への提供および預託
            </p>
            <p>
              お客様の個人情報は、以下掲げる場合に限って、第5項に記載する条件に基づいて、当社以外の第三者に提供または預託することがあり弊社は一切の責任を負わないことを同意します。
            </p>
            <p>・本サービスに関するご利用料金の請求／決済／徴収に必要な場合</p>
            <p>・本サービスの全部または一部を第三者に委託する場合</p>
            <p>・当社に対して秘密保持義務を負うものに対して開示する場合</p>
            <p>・当社の権利行使に必要な場合</p>
            <p>
              ・合併、営業譲渡その他の事由による事業の承継の際に事業を承継する者に対して開示す場合
            </p>
            <p>・個人情報保護法、その他法令により認められた場合</p>
            <p>
              ・法令または裁判所その他の政府機関より適法に開示を要求された場合
            </p>
            <p>・その他特定の目的のためにお客様から同意を得た場合</p>
            <p>
              ・ユーザー自らが本サービスの機能または別の手段を用いて利用企業等に個人情報を明らかにする場合（なお、利用企業等における個人情報の取扱いについては、各利用企業に直接お問合せください）
            </p>
            <p>
              ・本サービスに入力した情報により、期せずして本人が特定できてしまった場合
            </p>
            <p>
              ・本サービスからリンクされる外部サイトにおいて、ユーザーより個人情報が提供され、またそれが利用された場合
            </p>
            <p className={styles.title}>
              第5条　提供または預託する際の当該協力会社との守秘義務
            </p>
            <p>
              当社の業務の全部または一部を外部に業務委託する際、当社は個人情報を適切に保護できる管理体制を敷き実行していることを条件として委託先を厳選したうえで、「個人情報取扱いに関する機密保持契約」を委託先と締結し、お客様の個人情報を厳密に管理します。
            </p>
            <p className={styles.title}>第６条　個人情報の適切な保護</p>
            <p>
              当社は、お客様の個人情報を適切かつ安全に管理し、外部からの個人情報への不正アクセスやその不正使用、紛失、破壊、改ざんおよび漏洩等を予防する保護策を講じています。
            </p>
            <p className={styles.title}>
              第7条　個人情報の使用停止・削除等での対応
            </p>
            <p>
              お客様は、当社に対してご自身の個人情報の開示・訂正・削除を要求、当社がお客様の個人情報の利用、第三者への提供または預託することを中止させるために、以下お問い合わせ窓口に申し出ることができます。その際、当社はお客様ご本人であることを確認させていただいたうえで、合理的な期間内に対応いたします。
            </p>
            <p>〒160-0022</p>
            <p>東京都新宿区新宿六丁目27番30号 新宿イーストサイドスクエア8階</p>
            <p>株式会社ビジョン タイムマッチ運営</p>
            <p>E-Mail： 「 」</p>
            <p className={styles.title}>第８条　法令、規範の遵守と見直し</p>
            <p>
              当社は、当社の保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
            </p>
            <p>
              個人情報の利用の目的をできる限り特定し、当該目的の達成に必要な範囲内で適切に取扱います。また、目的外利用を行なわないための措置を講じます。
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default connect(({ MASTER }) => ({
  masterStore: MASTER,
}))(PrivacyPolicy);
