import React, { useCallback, useEffect, useState } from 'react';
import AdjustmentList from '@/pages/Top/AdjustmentList';
import SwipableItem from '@/components/SwipableItem';
import { connect } from 'dva';
import { Spin } from 'antd';
import moment from 'moment';
import { getStep } from '@/commons/function.js';
import { history, useIntl } from 'umi';
import Tab from '../../../models/Tab';
import '../styles.less';
import { profileFromStorage } from '@/commons/function';

const PastEvent = props => {
  const { dispatch, calendarStore, tabStore, onDataEvent } = props;
  const intl = useIntl();
  const { formatMessage } = intl;
  const { listUpcomingCalendar } = calendarStore;
  const { adjustedEvents, tabLoading } = tabStore;
  const pageSize = 1e10;
  const [pageIndex, setPageIndex] = useState(1);
  const profile = profileFromStorage();
  const [listCheckRole, setListCheckRole] = useState([]);

  const payload = {
    user_id_of_member: profile?.id,
    relationship_type: 3,
    is_finished: 1,
  };

  const getList = () => {
    dispatch({
      type: 'TAB/getOnePaginateAdjustedEventsMember',
      payload,
    });
  };

  useEffect(() => {
    if (window.location.search === '?tab=2') getList();
    handleDelete(history.location.state?.event_id);
  }, []);

  useEffect(() => {
    if (adjustedEvents?.data?.length > 0) {
      onDataEvent(true);
    }
  }, [adjustedEvents]);

  const handleDelete = id => {
    // remove id from adjustingEvents list and update the state
    const newAdjustedEvents = adjustedEvents?.data.filter(
      event => event.id !== id,
    );
    dispatch({
      type: 'TAB/setAdjustedEvents',
      payload: {
        data: newAdjustedEvents,
      },
    });
  };

  const handleEventDetail = item => {
    const fullUrl = item?.vote?.full_url?.split('=')[
      item?.vote?.full_url?.split('=')?.length - 1
    ];
    history.push(
      `/past-appointment/${item?.id}?id=${
        item.vote.slug
      }&name=${fullUrl}&chooseSchedule=true&role=${false}`,
    );
  };

  const calculateSwipeableListHeight = h => {
    const headerHeight = h;
    const footerHeight = 50;
    const windowHeight = window.innerHeight;

    return windowHeight - headerHeight - footerHeight;
  };

  return (
    <Spin spinning={tabLoading}>
      <AdjustmentList
        height={calculateSwipeableListHeight(150)}
        renderItem={(item, index) => (
          <SwipableItem index={index} item={item} onDelete={handleDelete}>
            <div
              onClick={() => handleEventDetail(item)}
              className="swipableItem"
              data-current-time={moment(item?.calendars[0]?.start_time).format(
                'YYYY-MM-DD HH:mm:ss',
              )}
            >
              <div className="swipableItemInner">
                <div className="swipableItemInnerDiv"></div>
                <div>
                  {/* format date time by japanese */}
                  <span>
                    {moment(item?.calendars[0]?.start_time).format(
                      'MMMM Do (dd) HH:mm',
                    )}
                  </span>
                  <span>～</span>
                  <span>
                    {moment(item?.calendars[0]?.start_time)
                      .add(getStep(item), 'minutes')
                      .format('HH:mm')}
                  </span>
                </div>
              </div>
              <div className="flexSpaceBetween">
                <div className="flex-0-5">イベント名</div>
                <div>:</div>
                <div
                  style={{
                    wordBreak: 'break-word',
                  }}
                  className="flex1"
                >
                  {item && item?.name}
                </div>
              </div>
              <div className="flexSpaceBetween">
                <div className="flex-0-5">
                  {formatMessage({ id: 'i18n_vote_owner' })}
                </div>
                <div>:</div>
                <div className="flex1">
                  {item && item.user_id === profile?.id
                    ? formatMessage({ id: 'i18n_label_event_created_by_me' })
                    : `${item?.user?.name} ${formatMessage({
                        id: 'i18n_label_event_created_by_other',
                      })}`}
                </div>
              </div>
              {/*<div className="flexSpaceBetween">*/}
              {/*  <div className="flex-0-5">*/}
              {/*    {formatMessage({ id: 'i18n_memo' })}*/}
              {/*  </div>*/}
              {/*  <div>:</div>*/}
              {/*  <div className="flex1">*/}
              {/*    {item && item?.calendars[0]?.calendar_create_comment}*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </SwipableItem>
        )}
        data={adjustedEvents?.data}
      />
    </Spin>
  );
};

export default connect(({ CALENDAR, TAB }) => ({
  calendarStore: CALENDAR,
  tabStore: TAB,
}))(PastEvent);
